import Text from "../../atoms/text";
import Container from "../../atoms/container";
import Image from "../../atoms/image";
import Button from "../../atoms/button";
import TextBox from "../../molecules/textBox";


export default function BecomeAPartnerDetail(props) {

    const {
        banner,
        sectionTitle,
        business,
        firstSection,
        button,
        setIsModalOpen
    } = props;


    if (!props) {
        return <></>;
    }

    return (
        <div className="becomeAPartner-area">
            { firstSection && <div className="margin-firstSectionTop" /> }
            { banner &&          
                <section className="becomeAPartnerDetail banner">
                    <Container size="md">
                        { banner && banner?.title &&
                            <Text 
                                type="h1"
                                font="poppins-medium"
                                fontSize="font-52"
                                color="white"
                                text={banner?.title}
                                maxLines="2"
                            />
                        }
                        { banner && banner?.description &&
                            <Text 
                                type="h2"
                                font="poppins-light"
                                fontSize="font-32"
                                color="yellow"
                                text={banner?.description}
                                maxLines="3"
                            /> 
                        }
                        { banner && banner?.background_image && banner?.background_image?.data && banner?.background_image?.data?.attributes && banner?.background_image?.data?.attributes?.url &&
                            <img className="background-image" src={process.env.REACT_APP_API + banner?.background_image?.data?.attributes?.url} alt="background image"/>
                        }

                        <div className="mouse-icon-container">
                            <Image src="/images/seta_scrolldown.svg" alt="mouse" />
                        </div> 
                    </Container>
                </section>
            }
            { business && business.length > 0 &&
                <section className="becomeAPartnerDetail businessModel margin-sectionTop margin-sectionBottom">
                    <Container size="md">
                        <Text 
                            type="h3"
                            font="poppins-medium"
                            fontSize="font-36"
                            color="yellow"
                            text={sectionTitle}
                            maxLines="3"
                        />
                        { business.map((item, index) => (
                            <div key={index}>
                                 <Text 
                                    type="h3"
                                    font="poppins-medium"
                                    fontSize="font-30"
                                    color="white"
                                    text={item?.title}
                                    maxLines="3"
                                />
                                <div className="description">
                                    <TextBox
                                        text={item?.description}
                                    /> 
                                </div>
                            </div>
                        ))}
                       
                        <div className="buttons-container">
                            { button && button.title && button.isForm &&                                                         
                                <Button type="fullColored" bgcolor="bgYellow" textcolor="textWhite poppins" text={button.title} margin='ml16' onClick={() => setIsModalOpen(true)} /> 
                            }
                            { button && button.title && !button.isForm && button.url && 
                                <Button type="lineColored" bgcolor="bgYellow" textcolor="textWhite poppins" text={button.title} link={button.url} />
                            }
                        </div>

                    </Container>
                </section> 
            }           
        </div>
    );
}