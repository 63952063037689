import { useState, useEffect } from "react";

import IntroSection from "../../components/sections/introSection";
import CatalogueList from "../../components/sections/catalogueList";



export default function Catalogue({currentLang,setHeaderDarkMode}) {

  /* HEADER DARKMODE */

    useEffect(() => {
      setHeaderDarkMode(true);
    }, []);

  /* **************** */

  const [data, setData] = useState(null);
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const [response1, response2] = await Promise.all([
            fetch(`${process.env.REACT_APP_API}/api/catalogues?populate=deep&pagination[limit]=-1`),
            fetch(`${process.env.REACT_APP_API}/api/cataloguepage?populate=banner.background_image&locale=${currentLang}`),
          ])
          if (!response1.ok || !response2.ok) {
            throw new Error('Network error');
          }
  
          const [result1, result2] = await Promise.all([
            response1.json(),
            response2.json()
            
          ])
  
          setData(result1);
          setPage(result2);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);


    let introContent, listContent;

    if (page?.data?.attributes?.banner) {
        introContent = page.data.attributes.banner;
    }

    if (data) {
        listContent = data
    }
    
    
    if (loading) return <div className="loadingPage"></div>;
    
    return (
        <>
            {/* BANNER */}
            { introContent !== null && introContent !== undefined  &&
                <IntroSection bg="white"  {...introContent} mb24 firstSection/>
            }
            {/* CATALOGUE LIST */}
            { listContent !== null && listContent !== undefined  &&
                <CatalogueList bg="white" {...listContent}/>
            }

        </>
    )
}