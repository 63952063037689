import { useState, useEffect } from "react";

import IntroSection from "../../components/sections/introSection";
import BlocksLinkList from "../../components/sections/blocksLinkList";
import CategoriesList from "../../components/sections/categoriesList";
import Container from "../../components/atoms/container";
import Button from "../../components/atoms/button";


export default function News({ currentLang, setHeaderDarkMode }) {

  /* HEADER DARKMODE */

  useEffect(() => {
    setHeaderDarkMode(false);
  }, []);

  /* **************** */

  const [newsData, setNewsData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  // NEWS SLIDER ACTIVE ITEM
  const [newItemActive, setNewItemActive] = useState("showAll");
  const [hasMore, setHasMore] = useState(true);
  const [pagination, setPagination] = useState(1)

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response1, response2] = await Promise.all([
          fetch(`${process.env.REACT_APP_API}/api/news?fields[0]=slug&fields[1]=pin&fields[2]=title&fields[3]=summary&fields[4]=date&locale=${currentLang}&pagination[page]=${pagination}&publicationState=${process.env.REACT_APP_PREVIEW}&sort[0]=pin:desc&sort[1]=date:desc&populate=thumbnail_image`),
          fetch(`${process.env.REACT_APP_API}/api/newspage?populate=deep&locale=${currentLang}`),
        ])
        if (!response1.ok || !response2.ok) {
          throw new Error('Network error');
        }

        const [result1, result2] = await Promise.all([
          response1.json(),
          response2.json()

        ])

        setNewsData(result1.data);
        setOriginalData(result1.data);
        setPage(result2);
        setHasMore(result1.meta.pagination.page < result1.meta.pagination.pageCount);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pagination]);


  // SET INFO TO SHOW NEWS
  const banner = page?.data?.attributes?.banner;
  const sorting = page?.data?.attributes?.sorting;

  const loadMore = () => {
    if (hasMore) {
      setPagination((prev) => prev + 1);
    }
  };

  const getSortedData = (newItemActive, newsData) => {

    if (newItemActive === "showAll") return originalData; // Reset to original order
    else if (newItemActive === "latest") return [...newsData].sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));
    else if (newItemActive === "oldest") return [...newsData].sort((a, b) => new Date(a.attributes.date) - new Date(b.attributes.date));
    else return newsData; // Default fallback
  };

  if (loading) return <div className="loadingPage"></div>;

  return (
    <>
      {/* BANNER */}
      {banner !== null && banner !== undefined &&
        <IntroSection {...banner} firstSection />
      }
      {/* CATEGORIES LIST */}
      {sorting &&
        <CategoriesList
          news={sorting}
          isNews
          newItemActive={newItemActive}
          setNewItemActive={setNewItemActive}
        />
      }
      {/* NEWS LIST */}
      {originalData !== null && originalData !== undefined &&
        <BlocksLinkList list={getSortedData(newItemActive, newsData)} />
      }
      {
        hasMore &&
        <div className="showMore-button-container margin-sectionBottom">
          <Container>
            <Button
              onclick={loadMore}
              type="fullColored bold"
              bgcolor="bgYellow"
              textcolor="textBlack"
              text={page?.data?.attributes?.loadMore?.text}
            />
          </Container>
        </div>
      }
    </>
  )
}