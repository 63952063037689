import Container from "../../atoms/container";
import Text from "../../atoms/text";
import Image from "../../atoms/image";
import TextBox from "../../molecules/textBox";


export default function ListTextImage (props) {

    const {
        textBlock,
        darkMode
    } = props
    

    if (!props) {
        return <></>;
    }

    return ( 
        <section className={`listTextImage ${darkMode ? 'bgDarkGrey' : 'bgWhite margin-sectionTop'} margin-sectionBottom `}>
            <Container size="md">
                {!darkMode && textBlock && textBlock.length > 0 && textBlock.map((item, index) => (
                    <div className="item" key={index}>
                        <div className="text-container">
                            {/* TITLE */}
                            {item?.title && 
                                <Text 
                                    type="h2"
                                    font="poppins-medium" 
                                    color="black"
                                    fontSize="font-32"  
                                    text={item.title} 
                                    maxLines="10"
                                />
                            }
                            {/* DESCRIPTION */}
                            {item?.description &&
                                <TextBox  
                                    text={item.description} 
                                    textLight
                                />
                            }
                        </div>
                        {/* IMAGE */}
                        <div className="image-container">
                            {item?.image?.data?.attributes?.url &&
                                <div>
                                    <div className="image-bg"></div>
                                    <div className="image">
                                        <Image src={process.env.REACT_APP_API + item?.image?.data?.attributes?.url} alt={item?.image?.data?.attributes?.alternativeText}/>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                ))}

                {darkMode && textBlock && textBlock.length > 0 && textBlock.map ((item, index) => (
                    <div className="item darkMode" key={index}>
                        {/* TEXT */}
                        {item?.text && 
                            <TextBox  
                                text={item.text} 
                                textLight
                            />
                        }
                        {/* IMAGE */}
                        {item?.image && item?.image?.data && item?.image?.data?.attributes && item?.image?.data?.attributes?.url &&
                            <div>
                                <div className="image-bg"></div>
                                <div className="image">
                                    <Image src={process.env.REACT_APP_API + item?.image?.data?.attributes?.url} alt={item?.image?.data?.attributes?.alternativeText}/>
                                </div>
                            </div>
                        }
                    </div>
                ))}
            </Container>
        </section>

    )
}