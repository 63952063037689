import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import IntroSectionImage from "../components/sections/introSectionImage";
import ListTextImage from "../components/sections/listTextImage";
import SimpleTextBlock from "../components/sections/simpleTextBlock";


export default function Solutions({ currentLang, setHeaderDarkMode }) {

    useEffect(() => {
        setHeaderDarkMode(true);
    }, []);

    /* **************** */


    let { slug } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!slug || !currentLang) return;
        let url = process.env.REACT_APP_API + `/api/solutions?filters[slug][$eq]=${slug}&publicationState=${process.env.REACT_APP_PREVIEW}&[populate]=deep&locale=${currentLang}`
        const fetchData = async () => {
            try {
                const response = await fetch(url)
                console.log(response)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);


    //Redirect in case wrong slug
    useEffect(() => {
        if (data && data.length === 0) {
            navigate("/");
        }
    }, [data, navigate]);

    if (loading) return <div className="loadingPage"></div>;


    return (
        <>
            {data && data.length > 0 && data[0] && data[0]?.attributes &&
                <IntroSectionImage bg="light" {...data[0].attributes} firstSection />
            }
            {data && data.length > 0 && data[0] && data[0]?.attributes &&
                <SimpleTextBlock {...data[0].attributes} />
            }
            {data && data.length > 0 && data[0] && data[0]?.attributes && data[0]?.attributes?.textBlock &&
                <ListTextImage textBlock={data[0].attributes.textBlock} />
            }
        </>
    )
}