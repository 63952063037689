import { useState } from "react";
import Container from "../../atoms/container";

export default function SliderCategories(props) {


    const {
        news,
        data,
        isNews,
        isCaseStudies,
        newItemActive,
        setNewItemActive,
        caseStudyItemActive,
        setCaseStudyItemActive
    } = props;



    if (!props) {
        return <></>;
    }
    
    const categoryArray = news ? Object.keys(news).filter(key => key !== "id") : [];

    return (
        <div className="sliderCategories">
            {news && isNews &&
                <Container size="md-right">
                    {categoryArray.map((key) => (
                        <div
                            key={key}
                            className={`item ${newItemActive === key ? "active" : ""}`}
                            onClick={() => setNewItemActive(key)}
                        >
                            {news[key].text}
                        </div>
                    ))}
                </Container>
            }
            {isCaseStudies && data &&
                <Container size="md-right">
                    {/*                 {data && data.length > 0 &&
                    <div className={`item ${caseStudyItemActive === -1 && "active"}`} onClick={() => setCaseStudyItemActive(-1)}>
                        All
                    </div>
                } */}
                    {data.length > 0 && data.map((item, index) => (
                        <div className={`item ${caseStudyItemActive === (index) && "active"}`} key={index} onClick={() => setCaseStudyItemActive(index)}>
                            {item.attributes.category}
                        </div>
                    ))}
                </Container>}
        </div>
    )
}