import { Link } from "react-router-dom";

import Text from "../../atoms/text";
import Container from "../../atoms/container";
import Image from "../../atoms/image";
import Button from "../../atoms/button";



export default function CaseStudyBody(props) {

    const {
        textBlock,
        seeMoreContent,
        seeMoreSection
    } = props;

    
    if (!props) {
        return <></>;
    }

    return (
        <section className='caseStudyBody'>
            {textBlock &&
                <div className="text-container margin-sectionBottom">
                    {textBlock.map((item, index) => {                       
                        return (
                            <div className={`block ${index === 0 ? 'margin-sectionTop' : ''}`} key={index}>
                                <div></div>
                                <Container size="md" >

                                    {/* BLOCK TITLE */}

                                    {item.title &&
                                        <Text
                                            className="title"
                                            type="h3"
                                            font="poppins-medium"
                                            fontSize="font-32"
                                            color="yellow"
                                            text={item.title}
                                        />
                                    }

                                    {/* BLOCK TEXT */}

                                    {item.description &&
                                        <Text
                                            className="text"
                                            type="p"
                                            font="roboto-light"
                                            fontSize="font-24"
                                            color="grey"
                                            text={item.description}
                                        />
                                    }

                                    {/* BLOCK IMAGES */}

                                    {item.image && item.image.data && item.image.data[0] &&                                        
                                        <div className={`images-container ${(item.image && item.image.data && item.image.data[0] && item.image.data[0].attributes && item.image.data[0].attributes.url) 
                                            && (item.image && item.image.data && item.image.data[1] && item.image.data[1].attributes && item.image.data[1].attributes.url) ? 'double' : 'single'}`}>
                                            {((item.image && item.image.data && item.image.data[0] && item.image.data[0].attributes && item.image.data[0].attributes.url) 
                                            || (item.image && item.image.data && item.image.data[1] && item.image.data[1].attributes && item.image.data[1].attributes.url)) &&
                                                <>
                                                    {item.image && item.image.data && item.image.data[0] && item.image.data[0].attributes && item.image.data[0].attributes.url &&
                                                        <Image src={process.env.REACT_APP_API + item.image.data[0].attributes.url} alt={'image'} />
                                                    }
                                                    {item.image && item.image.data && item.image.data[1] && item.image.data[1].attributes && item.image.data[1].attributes.url &&
                                                        <Image src={process.env.REACT_APP_API + item.image.data[1].attributes.url} alt={'image'} />
                                                    }
                                                </>
                                            }
                                        </div>
                                    }
                                    { item.image && !item.image.data &&
                                        <div className='no-images'/>
                                    }
                                </Container>
                            </div>
                        )
                    })}
                </div>
            }


            {/* SEE MORE AREA */}
            <div className="seeMoreArea margin-sectionTop margin-sectionBottom">
                <Container size='md'>
                    {seeMoreSection.title &&
                        <Text
                            type="h1"
                            font="poppins-medium"
                            fontSize="font-32"
                            color="black"
                            text={seeMoreSection.title}
                            maxLines="2"
                        />
                    }
                    <div className="options">
                        {seeMoreContent?.map((item, index) => (
                            <Link to={`/resources/casestudies/${item?.attributes.slug}`} key={index}>
                                <div className="item" key={index}>
                                    <div className="image-container">
                                        {item.attributes.thumbnail_image &&
                                            <Image src={process.env.REACT_APP_API + item.attributes.thumbnail_image.data.attributes.url} alt={process.env.REACT_APP_API + item.attributes.thumbnail_image.data.attributes.alternativeText} />
                                        }
                                        <div className="colorHover"></div>
                                        <div className="bottom-content">
                                            {item.category &&
                                                <div className="category-container">
                                                    <Text
                                                        type="span"
                                                        font="poppins-regular"
                                                        fontSize="font-22"
                                                        color="white"
                                                        text={item.category}
                                                        maxLines="2"
                                                    />
                                                </div>
                                            }
                                            <Button arrowRight />

                                        </div>
                                    </div>
                                    {item.attributes.title &&
                                        <Text
                                            type="h3"
                                            font="poppins-semibold"
                                            fontSize="font-24"
                                            color="black"
                                            text={item.attributes.title}
                                            maxLines="2"
                                        />
                                    }
                                    {item.attributes.summary &&
                                        <Text
                                            type="span"
                                            font="poppins-regular"
                                            fontSize="font-13"
                                            color="black"
                                            text={item.attributes.summary}
                                            maxLines="2"
                                        />
                                    }

                                </div>
                            </Link>
                        ))}
                    </div>
                    {seeMoreSection.button &&
                        <div className="button-container">
                            {seeMoreSection.button.text &&
                                <Button
                                    type="fullColored"
                                    bgcolor="bgYellow"
                                    textcolor="textWhite poppins"
                                    text={seeMoreSection.button.text}
                                    page={seeMoreSection.button.url}
                                />
                            }
                        </div>
                    }
                </Container>
            </div>

        </section>

    )
}