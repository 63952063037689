import { useState, useEffect } from 'react';

import BecomeAPartnerDetail from '../../components/sections/becomeAPartnerDetail';

export default function BecomeAPartner({currentLang,setHeaderDarkMode,setIsModalOpen}) {


    /* HEADER DARKMODE */

    useEffect(() => {
        setHeaderDarkMode(false);
    }, []);

    /* **************** */


    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API}/api/becomeapartnerpage?populate=deep&locale=${currentLang}`)
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setData(result);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    if (loading) return <div className='loadingPage'></div>;

    return (
      <>
        { data?.data?.attributes &&
          <BecomeAPartnerDetail  firstSection {...data?.data?.attributes}  setIsModalOpen={setIsModalOpen}/>
        }
      </>
    )
}