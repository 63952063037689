import Section404 from "../components/sections/section404";

import data404 from "../components/sections/section404/data"



export default function ErrorPage(){

    return (
        <>
            <Section404 {...data404}/>
        </>
    )
}