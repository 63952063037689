import { useState } from "react";

import TextBox from "../../molecules/textBox";
import Image from "../../atoms/image";



export default function AccordionSection (props) {


    const [active, setActive] = useState(false);

    const changeState = () => {
        setActive(!active);
    }
    
    const {
        question,
        answer
    } = props;
    

    return (
        question && answer ?
            <div className={`accordion-box ${active ? 'open' : 'closed'}`} onClick={changeState}>
                <div className="title-container">
                    <TextBox
                        text={question}
                        textLight
                    />
                    <div className="button" onClick={changeState}><Image src="/images/accordion-button.svg" alt="button"/></div>
                </div>
                <div className="description-container">
                    <TextBox
                        text={answer}
                        textLight
                    />
                </div>
            </div>
        : 
        <div className={`accordion-box simple`}>
            <div className="title-container full">
                <TextBox
                    text={answer}
                    textLighter
                />
            </div>
        </div>
    )
}
