import Text from "../../atoms/text";
import Container from "../../atoms/container";
import TextBox from "../../molecules/textBox";


export default function ProjectDetail (props) {

    const {
        data
    } = props;

    
    if (!props) {
        return <></>
    }

    console.log(props)
    
    // PROJECTS AND FUNDINGS PAGE -> LIST OF ALL PROJECTS
    return ( 

        <section className="projectDetail margin-sectionBottom">
            <Container size="md">                
                {data && data.project && data.project.length > 0 && data.project.map ((proj, index) => (
                    <div className="projectBlock margin-sectionBottom" key={index}>
                        {/* TITLE */}
                        { proj?.title &&
                            <Text 
                            type="h1"
                            font="poppins-medium"
                            fontSize="font-66"
                            color='black'
                            text={proj?.title}
                            maxLines="3"
                            className="project-name"
                            />
                        }
                         {/* DESCRIPTION WITH ALL INFO */}
                        { proj?.description &&
                            <TextBox text={proj?.description} page="projects"/>
                        }
                    </div> 
                ))}                            
            </Container>
        </section>

    )
}