import Text from "../../atoms/text";
import Container from "../../atoms/container";
import ProductInfo from "../../molecules/productInfo";


export default function Products (props) {

    const {
        products
    } = props

    
    if (!props) {
        return <></>;
    }

    return ( 
        <section className="products margin-sectionBottom">
            <Container size="md">
                {/* LIST OF PRODUCTS */}
                {products &&
                    <div className="products-list">
                        {products && products.length > 0 && products.map((product, index) => {
                            return (
                                <ProductInfo key={index} {...product}/>
                            )
                        })}              
                    </div>
                } 
            </Container>
        </section>

    )
}