import { useState, useEffect } from "react";
import { useParams, useNavigate} from "react-router-dom";

import NewsBody from "../../components/sections/newsBody";

export default function NewsDetail({currentLang,setHeaderDarkMode}) {

    /* HEADER DARKMODE */
    
        useEffect(() => {
            setHeaderDarkMode(false);
        }, []);
  
    /* **************** */

    let {slug} = useParams()
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!slug || !currentLang) return;
        let url = process.env.REACT_APP_API + `/api/news?filters[slug][$eq]=${slug}&publicationState=${process.env.REACT_APP_PREVIEW}&[populate]=deep&locale=${currentLang}`
            const fetchData = async () => {
                try {
                    const response = await fetch(url)
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const result = await response.json();
                    setData(result?.data);
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
    }, [slug]);

    //Redirect in case wrong slug
    useEffect(() => {
        if (Array.isArray(data) && data.length === 0) {
          navigate("/resources/news");
        }
      }, [data, navigate]);

    if (loading) return <div className="loadingPage"></div>;


    return (
        <>
        {
            data[0]?.attributes &&
            <NewsBody {...data[0].attributes} firstSection/>
        }
        </>
    )
}