import { useState, useEffect } from 'react';

import DevicesCompatibilityDetail from '../../components/sections/devicesCompatibilityDetail';
import IntroSection from '../../components/sections/introSection';

export default function DevicesCompatibility({currentLang,setHeaderDarkMode}) {

    /* HEADER DARKMODE */

        useEffect(() => {
        setHeaderDarkMode(false);
        }, []);

    /* **************** */

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/devicecompatibilitypage?populate=deep&locale=${currentLang}`)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div className='loadingPage'></div>;
    
    return (
        <>
        { data?.data?.attributes?.banner &&
            <IntroSection {...data?.data?.attributes?.banner} firstSection/> 
        }
        { data?.data?.attributes?.devices &&
            <DevicesCompatibilityDetail header={data?.data?.attributes?.header} devices={data?.data?.attributes?.devices} />
        }
        </>
    )
}