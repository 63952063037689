import Text from '../../atoms/text';
import Container from '../../atoms/container';


{/* SIMPLE SECTION WITH PAGETITLE, TITLE AND DESCRIPTION */}
export default function IntroSection (props) {

    const {
        bg = "dark",
        pageTitle,
        title,
        description,
        mb0,
        firstSection
    } = props

    
    if (!props) {
        return <></>;
    }
    
    return ( 
        <section className={`introSection bg-${bg} ${mb0 ? '' : 'margin-sectionBottom'} `}>
            <div className={`title-container ${firstSection && 'margin-firstSectionTop'}`}>
                <Container size="md">
                    <div className="text-container">
                        {pageTitle &&
                            <Text 
                                type="h3"
                                font="poppins-medium"
                                fontSize="font-28"
                                color="yellow"
                                text={pageTitle}
                                maxLines="2"
                            />
                        }
                        { title &&
                            <Text 
                                className={`title ${props.marginTitle ? 'mb' : ''}`}
                                type="h1"
                                font="poppins-medium"
                                fontSize="font-66"
                                color={`${description ? "white mb24" : "white"}`}
                                text={title}
                                maxLines="3"
                            />
                        }
                        { description &&
                            <Text 
                                type="p"
                                font="poppins-light"
                                fontSize="font-28"
                                color="lightGrey2"
                                text={description}
                                maxLines="10"
                            />
                        }
                    </div>
                </Container>
            </div>
        </section>

    )
}