import { useState } from "react";
import { useForm } from "react-hook-form";
import Turnstile, { useTurnstile } from "react-turnstile";

import Text from "../../atoms/text";
import { ReactSVG } from "react-svg";
import TextBox from "../../molecules/textBox";


export default function Modal(props) {

    const {
        isOpen,
        attributes,
        setIsModalOpen,
    } = props

    
    const turnstile = useTurnstile();
    const [token, setToken] = useState();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = async formData => {
        try {
            const payload = { data: formData, turnstile_response: token };
            var myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            var requestOptions = {
                method: 'POST',
                mode: 'cors',
                headers: myHeaders,
                body: JSON.stringify(payload)
            };
            fetch(process.env.REACT_APP_FORM, requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));
            alert("Message submitted successfully!")
            reset()
            setIsModalOpen(false)
        } catch (errors) {
            console.error('Error:', errors);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div>
                {attributes && attributes?.formTitle &&
                    <div className="title-container">
                        <Text
                            type="span"
                            font="poppins-medium"
                            color="white"
                            text={attributes.formTitle}
                            fontSize="font-52"
                        />
                    </div>
                }
                <div className="closeButton" onClick={() => setIsModalOpen(false)}>
                    <ReactSVG src="images/close-button.svg" />
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    { attributes && attributes?.name && attributes?.name?.text &&
                        <div>
                            <input
                                type="text"
                                placeholder={attributes?.name?.text}
                                {...register("name", { required: attributes?.name?.isRequired })}
                            />
                            { errors['name'] && attributes?.errorMessage && ( 
                                <span style={{ color: 'red' }}> {attributes?.errorMessage} </span> 
                            )}
                        </div>
                    }
                    { attributes && attributes?.company && attributes?.company?.text &&
                        <div>
                            <input
                                type="text"
                                placeholder={attributes?.company?.text}
                                {...register("company", { required: attributes?.company?.isRequired })}
                            />
                            { errors['company'] && ( 
                                <span style={{ color: 'red' }}> {attributes?.errorMessage} </span> 
                            )}
                        </div>
                    }
                    { attributes && attributes?.position && attributes?.position?.text &&
                        <div>
                            <input
                                type="text"
                                placeholder={attributes?.position?.text}
                                {...register("position", { required: attributes?.position?.isRequired })}
                            />
                            { errors['position'] && ( 
                                <span style={{ color: 'red' }}> {attributes?.errorMessage} </span> 
                            )}
                        </div>
                    }
                    { attributes && attributes?.country && attributes?.country?.text &&
                        <div>
                            <input
                                type="text"
                                placeholder={attributes?.country?.text}
                                {...register("country", { required: attributes?.country?.isRequired })}
                            />
                            { errors['country'] && ( 
                                <span style={{ color: 'red' }}> {attributes?.errorMessage} </span> 
                            )}
                        </div>
                    }
                    { attributes && attributes?.contact && attributes?.contact?.text &&
                        <div>
                            <input
                                type="text"
                                placeholder={attributes?.contact?.text}
                                {...register("contact", { required: attributes?.contact?.isRequired })}
                            />
                            { errors['contact'] && ( 
                                <span style={{ color: 'red' }}> {attributes?.errorMessage} </span> 
                            )}
                        </div>
                    }
                    { attributes && attributes?.email && attributes?.email?.text &&
                        <div>
                            <input
                                type="email"
                                placeholder={attributes?.email?.text}
                                {...register("email", { required: attributes?.email?.isRequired })}
                            />
                            { errors['email'] && ( 
                                <span style={{ color: 'red' }}> {attributes?.errorMessage} </span> 
                            )}
                        </div>
                    }
                    { attributes && attributes?.message && attributes?.message?.text &&
                        <div>
                            <input
                                type="text"
                                placeholder={attributes?.message?.text}
                                {...register("message", { required: attributes?.message?.isRequired })}
                            />
                            { errors['message'] && ( 
                                <span style={{ color: 'red' }}> {attributes?.errorMessage} </span> 
                            )}
                        </div>
                    }
                    {
                        isOpen ?
                            <Turnstile
                                theme="dark"
                                refreshExpired="auto"
                                sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                                onVerify={(token) => {
                                    setToken(token)
                                }}
                            />
                            :
                            <></>
                    }
                    { attributes && attributes?.acceptTerms &&
                        <div className="bottom-div">
                            <div className="termsofservice">
                                <label className="container">
                                    <input type="checkbox" name="terms" required />
                                    <span className="checkmark"></span>
                                </label>
                                <TextBox
                                    text={attributes?.acceptTerms}
                                />
                            </div>
                            { attributes && attributes?.submitButton && 
                                <input type="submit" value={attributes?.submitButton} />
                            }
                        </div>
                    }
                </form>
            </div>
        </div>
    )
}
