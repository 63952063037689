import { useState } from "react";

import Text from "../../atoms/text";


export default function SliderDeviceModels (props) {

    const {
        slider,
    } = props;

    const [active, setActive] = useState(0);
    let options = slider[active]?.model;


    const showOptions = (index) => {
        setActive(index);

        options = slider[active]?.model;
    }

    return (
        <div className="sliderDeviceModels margin-sectionBottom">
            <div className='sliderOptions'>
                <div>
                    {slider && slider.length > 0 && slider.map ((item, index) => (
                        <div className={`item ${active === (index) && "active"}`} key={index} onClick={() => showOptions(index)}>
                            <span>{item?.brand}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className='sliderResults'>
                <div>
                    {options && options.length > 0 && options.map ((item, index) => (
                        <div className={`item ${active === (index) && "active"}`} key={index}>
                            <Text
                                type="span"
                                font="poppins-regular"
                                color="lightGrey"
                                fontSize="font-24"
                                text={item?.text}
                                maxLines='3'
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}