import { useState, useEffect } from 'react';

import LegalDetail from '../components/sections/legalDetail';

export default function Legal({currentLang,setHeaderDarkMode}) {

    /* HEADER DARKMODE */

        useEffect(() => {
            setHeaderDarkMode(true);
        }, []);

    /* **************** */


    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/legalpage?locale=${currentLang}&populate=deep`)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div className='loadingPage'></div>;

    return (
        <>
            {data?.attributes &&
                <LegalDetail {...data.attributes} firstSection/>
            }
        </>
    )
}