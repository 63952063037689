import { useState } from "react";
import { Link } from "react-router-dom";

import Container from "../../atoms/container";
import Text from "../../atoms/text";


export default function Menu (props) {

    const {
        menu,
        open,
        setMenuIsOpen
    } = props;

    const [itemActive, setItemActive] = useState(-1);

    return (
        <div id="menu" className={`menu ${open ? 'open' : ''}`}>
            <div className="content">
                <Container size='lg'>
                    {menu && menu.length > 0 && menu.map((menuItem, index) => {
                        return(
                        <div className="option-menu-container" key={index}>
                            <div onClick={() => setItemActive(itemActive === index ? -1 : index)}>
                                <Text
                                    type="h3"
                                    font="poppins-medium" 
                                    color="white" 
                                    text={menuItem.title} 
                                    fontSize="font-52"
                                />                            
                            </div>
                            <div className={`dropdown-menu-container ${itemActive === index ? 'open' : ''}`}>
                                {menuItem.navItem && menuItem.navItem.length > 0 && menuItem.navItem.map((subMenuItem, subIndex) => (
                                    <Link to={subMenuItem.url} key={subIndex} onClick={() => setMenuIsOpen(false)}>
                                        <Text
                                            type="span"
                                            font="poppins-regular" 
                                            color="yellow" 
                                            text={subMenuItem.title} 
                                            fontSize="font-32"                                            
                                        />
                                    </Link>
                                ))}
                            </div>                            
                        </div>
                    )})}  
                </Container>  
            </div>            
        </div>
    )
}