import Text from "../../atoms/text";
import Container from "../../atoms/container";
import Image from "../../atoms/image";
import Button from "../../atoms/button";
import TextBox from "../../molecules/textBox";


export default function NewsDetail(props) {

    const title = props?.title;
    const summary = props?.summary;
    const publishedAt = props?.date;
    const body_image = props?.news_body_image;
    const description = props?.news_body;
    const button = props?.backTo;
    const pageTitle = props?.pageTitle

    const {
        firstSection
    } = props;
    

    if (!props) {
        return <></>;
    }

    return (
        <section className={`newsDetail margin-sectionBottom ${firstSection && 'margin-firstSectionTop'}`}>
            <Container size="md">
                <div className="text-container">
                    <Text
                        type="h3"
                        font="poppins-medium"
                        fontSize="font-28"
                        color="yellow"
                        text={pageTitle}
                    />
                    <div className="title-container">
                        {title &&
                            <Text
                                type="h1"
                                font="poppins-medium"
                                fontSize="font-52"
                                color="white"
                                text={title}
                            />
                        }
                        {publishedAt &&
                            <Text
                                className="date"
                                type="span"
                                font="poppins-regular"
                                fontSize="font-30"
                                color="grey"
                                text={new Date(publishedAt).toLocaleDateString('en-GB').replace(/\//g, '-')}
                            />
                        }
                    </div>
                    {summary &&
                        <Text
                            type="p"
                            font="poppins-light"
                            fontSize="font-32"
                            color="lightGrey"
                            text={summary}
                        />
                    }
                </div>
                <div className="newContent-container">
                    {body_image && body_image?.data && body_image?.data?.attributes && body_image?.data?.attributes?.url &&
                        <Image src={process.env.REACT_APP_API + body_image.data.attributes.url} alt={"new image"} />
                    }
                    <div className="newContent-text">
                        <TextBox text={description} page="news-body"/>
                       
                    </div>
                </div>

                {button && button?.url &&  button?.text && 
                    <div className="button-container">
                        <Button
                            type="fullColored"
                            bgcolor="bgYellow"
                            textcolor="textBlack"
                            text={button.text}
                            page={button.url}
                        />
                    </div>
                }
            </Container>
        </section>

    )
}