import Text from "../../atoms/text";
import { Link } from "react-router-dom";


export default function TextBox (props) {

    const {
        text,
        page,
        textLight
    } = props;
    

    {/* MODIFICAR FONTS CONFORME PÁGINA ATRAVÉs DE IF's */}
    let titleFont = "roboto-light";
    let titleSize = "font-36";
    let textSize = "font-24"
    let font = "roboto-light";
    let fontBold = "roboto-medium";
    let fontItalic = "roboto-light-italic";
    let fontBoldItalic = "roboto-medium-italic";

    /* CSS PROJECTS PAGE */
    if ( page === "projects") {
        textSize = "font-28"
    }

    return (
        <div className={`textBox-content ${page}`}>
            {text && text.length > 0 && text.map((item, index) => (
                <div className={item?.type} key={index}>
                    {/*  TITLES - HEADINGS  */}
                    {item.type && item.type === 'heading' && item.children && item.children.length > 0 && item.children.map((child, childIndex) => (
                        <Text
                            key={childIndex}
                            type="h2"
                            font={child?.text?.bold && !child?.text?.italic && fontBold || child?.text?.italic && !child?.text?.bold && fontItalic || (child?.text?.bold && child?.text?.italic && fontBoldItalic) || titleFont }
                            fontSize={titleSize}
                            color= {textLight ? 'lightGrey' : "black"}
                            text={child?.text}
                            className="heading"
                        />
                    ))}   

                    {/*  TEXT - PARAGRAPHS  */}
                    {item.type && (item.type === 'paragraph' || item.type === 'p') && item.children && item.children.length > 0 && 
                        <p>
                            {item.children.map((child, childIndex) => {
                                {/*  LINK  */}
                                if (child?.type === 'link') {
                                    return (
                                        <Link to={child?.url} key={childIndex} target="_blank" rel="noopener noreferrer">
                                            <Text                                            
                                                type="span"
                                                font={ child?.children[0]?.bold && !child?.children[0]?.italic && fontBold || child?.children[0]?.italic && !child?.children[0]?.bold && fontItalic || (child?.children[0]?.bold && child?.children[0]?.italic && fontBoldItalic) || font }
                                                fontSize={textSize}
                                                color="yellow"
                                                text={child?.children[0]?.text}
                                                className={child?.children[0]?.underline && !child?.children[0]?.strikethrough && 'underline' || child?.children[0]?.strikethrough && !child?.children[0]?.underline && 'lineThrough' || child?.children[0]?.strikethrough && child?.children[0]?.underline && 'underline_lineThrough'}
                                            />
                                        </Link>
                                    )
                                }
                                else {
                                    {/*  TEXT  */}
                                    return (                                    
                                        <Text
                                            key={childIndex}
                                            type="span"
                                            font={ child?.bold && !child?.italic && fontBold || child?.italic && !child?.bold && fontItalic || (child?.bold && child?.italic && fontBoldItalic) || font }
                                            fontSize={textSize}
                                            color={textLight ? 'lightGrey' : "black"}
                                            text={child?.text}
                                            className={child?.underline && !child?.strikethrough && 'underline' || child?.strikethrough && !child?.underline && 'lineThrough' || child?.strikethrough && child?.underline && 'underline_lineThrough'}
                                        />
                                    )
                                }
                            })}    
                        </p>
                    }

                    {/*  TEXT - LISTS  */}
                    {item.type && item.type === 'list' && item.children && item.children.length > 0 && item?.format === 'unordered' &&
                        <ul>
                            {item.children.map((child, childIndex) => (                            
                                <li key={childIndex}>
                                    <Text
                                        type="span"
                                        font={ child?.bold && !child?.italic && fontBold || child?.italic && !child?.bold && fontItalic || (child?.bold && child?.italic && fontBoldItalic) || font }
                                        fontSize={textSize}
                                        color={textLight ? 'lightGrey' : "black"}
                                        text={child?.children[0]?.text}
                                        className={child?.underline && !child?.strikethrough && 'underline' || child?.strikethrough && !child?.underline && 'lineThrough' || child?.strikethrough && child?.underline && 'underline_lineThrough'}
                                    />
                                </li>
                            ))}
                        </ul>                    
                    }

                    {item.type && item.type === 'list' && item.children && item.children.length > 0 && item?.format === "ordered" &&
                        <ol>
                            {item.children.map((child, childIndex) => (                            
                                <li key={childIndex}>
                                    <Text
                                        type="span"
                                        font={ child?.bold && !child?.italic && fontBold || child?.italic && !child?.bold && fontItalic || (child?.bold && child?.italic && fontBoldItalic) || font }
                                        fontSize={textSize}
                                        color={textLight ? 'lightGrey' : "black"}
                                        text={child?.children[0]?.text}
                                        className={child?.underline && !child?.strikethrough && 'underline' || child?.strikethrough && !child?.underline && 'lineThrough' || child?.strikethrough && child?.underline && 'underline_lineThrough'}
                                    />
                                </li>
                            ))}
                        </ol>                    
                    }
                        
                </div>
            ))}
        </div>
    )
}