import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";

import Header from "./components/organisms/header";
import Footer from "./components/organisms/footer";
import ScrollToTop from "./ToTheTop";


import Homepage from "./pages/homepage";
import ProductDetails from "./pages/product_details"
import Solutions from "./pages/solutions";
import BecomeAPartner from './pages/Partners/becomeapartner';
import Partnerships from './pages/Partners/partnerships';
import Catalogue from "./pages/Resources/catalogue";
import CaseStudies from "./pages/Resources/caseStudies";
import CaseStudiesDetail from "./pages/Resources/caseStudies_detail";
import DevicesCompatibility from "./pages/Resources/devicecompatibility";
import News from "./pages/Resources/news";
import NewsDetail from "./pages/Resources/news_detail";
import WhyCuco from "./pages/whyCuco";
import Faqs from "./pages/faqs";
import Legal from "./pages/legal";
import ProjectsAndFunding from "./pages/projectsandfunding";
import ErrorPage from "./pages/errorPage";



export default function App() {

  //Google Analytics page tracker
  ReactGA.initialize("G-61H45L52XE");
  const location = useLocation();
  useEffect(() => {
    logPageView(location.pathname + location.search); // Track page changes and send to google analytics
  }, [location]);

  const [cookies, setCookie] = useCookies(["language"]);
  const [currentLang, setCurrentLang] = useState('')
  const [locales, setLocales] = useState(null)

  const [headerData, setHeaderData] = useState(null);
  const [footerData, setFooterData] =useState(null);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  /* SET HEADER DARKMODE */
  const [headerDarkMode, setHeaderDarkMode] = useState(false);


  const updateCookie = useCallback((lang) => {
    setCookie('language', lang, { path: '/', maxAge: 30 * 24 * 60 * 60 });
  }, [setCookie]);

  useEffect(() => {
    const initializeLanguage = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API}/api/i18n/locales`);
            if (!res.ok) throw new Error('Error fetching locales');

            const data = await res.json();
            setLocales(data);

            if (cookies.language) {
                setCurrentLang(cookies.language);
            } else {
                const defaultLocale = data.find(locale => locale.isDefault) || data[0];
                if (defaultLocale) {
                    setCurrentLang(defaultLocale.code);
                    updateCookie(defaultLocale.code);
                }
            }
        } catch (error) {
            console.error('Error initializing language:', error);
        }
    };

    initializeLanguage();
}, [cookies.language, updateCookie]); 

  useEffect(() => {
    if (!currentLang) return;

    const fetchData = async () => {
      try {
        const [headerRes, footerRes, formRes] = await Promise.all([
          fetch(`${process.env.REACT_APP_API}/api/header?locale=${currentLang}&populate=deep`),
          fetch(`${process.env.REACT_APP_API}/api/footer?locale=${currentLang}&populate=deep`),
          fetch(`${process.env.REACT_APP_API}/api/requestdemo?locale=${currentLang}&populate=deep`)
        ]);

        if (!headerRes.ok || !footerRes.ok || !formRes.ok) throw new Error('Failed to fetch header/footer/form');

        const headerData = await headerRes.json();
        const footerData = await footerRes.json();
        const formData = await formRes.json();

        setHeaderData(headerData.data);
        setFooterData(footerData.data);
        setFormData(formData.data);
      } catch (err) {
        console.error('Error fetching header/footer:', err);
        
      } finally {
        setLoading(false);
      }

    };

    fetchData();
  }, [currentLang]);

  const handleLanguageChange = (newLang) => {
    setCurrentLang(newLang);
    updateCookie(newLang);
    if (newLang != currentLang){
      window.location.reload();
    }
  };

  if (loading) return <div className="loadingPage">Loading...</div>;

  return (
    <>
      <ScrollToTop />
      <Header
        {...headerData?.attributes}
        formModal={formData}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        headerDarkMode={headerDarkMode}
      />
      <Routes>
        {/* ERROR PAGE - 404 */}
        <Route path='*'
          element={
            <ErrorPage
              setHeaderDarkMode={setHeaderDarkMode} />
          }
        />
        {/* HOMEPAGE */}
        <Route path='/'
          element={
            <Homepage
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
            />
          }
        />
        {/* PRODUCTS */}
        <Route path="/products/:slug"
          element={
            <ProductDetails
              setIsModalOpen={setIsModalOpen}
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* SOLUTIONS */}
        <Route path="/solutions/:slug"
          element={
            <Solutions
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* CASE STUDIES */}
        <Route path="/resources/casestudies"
          element={
            <CaseStudies
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* CASE STUDIES DETAIL */}
        <Route path="/resources/casestudies/:slug"
          element={
            <CaseStudiesDetail
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* CATALOGUE */}
        <Route path="/resources/catalogue"
          element={
            <Catalogue
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* DEVICES COMPATIBILITY */}
        <Route path="/resources/devicecompatibility"
          element={
            <DevicesCompatibility
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* NEWS */}
        <Route path="/resources/news"
          element={
            <News
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* NEWS DETAIL */}
        <Route path="/resources/news/:slug"
          element={
            <NewsDetail
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* PARTNERSHIPS */}
        <Route path="/partners/partnerships"
          element={
            <Partnerships
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* BECOME A PARTNER */}
        <Route path="/partners/becomeapartner"
          element={
            <BecomeAPartner
              setHeaderDarkMode={setHeaderDarkMode} 
              currentLang={currentLang}
              setIsModalOpen={setIsModalOpen}
            />
          }
        />
        {/* WHY CUCO */}
        <Route path="/whycuco/:slug"
          element={
            <WhyCuco
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* FAQS */}
        <Route path="/support/faqs"
          element={
            <Faqs
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* LEGAL */}
        <Route path="/legal"
          element={
            <Legal
              setHeaderDarkMode={setHeaderDarkMode}
              currentLang={currentLang}
              />
          }
        />
        {/* PROJECTS */}
        <Route path="/projects"
          element={
            <ProjectsAndFunding
              setHeaderDarkMode={setHeaderDarkMode} 
              currentLang={currentLang}
              />
          }
        />
      </Routes>
      <Footer data={footerData} locales={locales} currentLang={currentLang} onLanguageChange={handleLanguageChange} />
    </>
  );
}

//Logs google analytics pageviews
const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};


