import Text from "../../atoms/text";


export default function StatisticsBox (props) {

    const {
        title,
        description,
        fullDark
    } = props

    return (
        <div className={`statisticsBox ${fullDark && 'fullDark'}`}>
            { title && 
                <Text 
                    type="h2"
                    font="poppins-semibold" 
                    color="yellow"
                    fontSize="font-40" 
                    text={title}
                    maxLines='1'
                />
            }
            
            { description && 
                <Text 
                    type="p"
                    font="roboto-light" 
                    color="lightGrey"
                    fontSize="font-20"
                    text={description}
                    maxLines='3'
                />
            }           
        </div>
    )
}
