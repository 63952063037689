import TextBox from "../textBox";
import Image from "../../atoms/image";


export default function CheckItem (props) {

    const {
        checkImage,
        text
    } = props;

    return (
        <div className="checkitem">
            <Image src={process.env.REACT_APP_API + checkImage.url} alt="check"/>
            { text && 
                <TextBox
                    text={text}
                    textLight
                />
            }
        </div>
    )
}
