import { useEffect, useRef, useState } from "react";

import Text from "../../atoms/text";
import Container from "../../atoms/container";
import Image from "../../atoms/image";


export default function TrustedBy(props) {

    const {
        title,
        logos
    } = props;
    
    
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
      const handleScroll = () => setScrollY(window.scrollY);
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);
  
   

    if (!props) {
        return <></>
    }

    return (
        <>
       
         <section className="trustedBy margin-sectionTop margin-small-sectionBottom">
            <Container size="md">
                <div className="content">
                    { title &&
                        <Text 
                            type="h1"
                            font="poppins-medium"
                            fontSize="font-40"
                            color="grey"
                            text={title}
                            maxLines="2"
                            className="margin-sectionBottom"
                        />
                    }
                </div>
            
                <div className="logos-container">  
                        <div className="firstRow">                 
                            { logos && logos.data && logos.data.length > 0 && logos.data.map((logo, index) => (
                                logo?.attributes?.url && index < 5 &&                                
                                <div className={`logo`} key={index} style={{ transform: `translateX(${-scrollY * 0.05}px) `}} >
                                    <Image src={process.env.REACT_APP_API + logo?.attributes?.url} alt="logo image"/>
                                </div>                  
                            ))}
                        </div>
                        { logos.data.length > 5 && 
                            <div className="secondRow margin-sectionBottom">                 
                                { logos && logos.data && logos.data.length > 0 && logos.data.map((logo, index) => (
                                    logo?.attributes?.url && index >=5 && index < 10 &&                                    
                                    <div className={`logo`} key={index} style={{ transform: `translateX(${scrollY * 0.05}px) `}} >
                                        <Image src={process.env.REACT_APP_API + logo?.attributes?.url} alt="logo image"/>
                                    </div>                  
                                ))}
                            </div>
                        }
                </div>
            </Container>
     </section>
     </>
    );
}