import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";


import Container from "../../atoms/container";
import Image from "../../atoms/image";
import Button from "../../atoms/button";
import Text from "../../atoms/text";
import Menu from "../menu";
import Modal from "../modal";


export default function Header(props) {

    const {
        logo,
        menu,
        buttons,
        formModal,
        isModalOpen,
        setIsModalOpen,
        headerDarkMode,
    } = props;

    const [scrolled, setScrolled] = useState(false);
    const [itemActive, setItemActive] = useState(-1);
    const [menuIsOpen, setMenuIsOpen] = useState(false);


    const changeActiveItem = (index) => {
        if (itemActive === index) {
            setItemActive(-1)
        }
        else {
            setItemActive(index)
        }
    }

    const listenScrollEvent = () => {
        if (window.scrollY <= 60) {
            setScrolled(false)
        } else if (window.scrollY > 60) {
            setScrolled(true)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () => window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0];

        if (menuIsOpen || isModalOpen) {
            body.classList.add("overflow-hidden");
        }
        else {
            body.classList.remove("overflow-hidden");
        }
    }, [menuIsOpen, isModalOpen]);


    /* CLICK OUTSIDE */
    function useOutsideAlert(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setItemActive(-1)
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlert(wrapperRef);

    if (!props) {
        return <></>;
    }

    return (
        <header className={`header ${headerDarkMode ? 'darkMode' : ''} ${scrolled ? 'bg-white' : ''}`}>
            <Container size='lg'>
                <div className="content">
                    {logo && logo?.data && logo?.data?.attributes && logo?.data?.attributes?.url &&
                        <Link to="/">
                            <Image className="header-logo" src={process.env.REACT_APP_API + logo.data.attributes.url} alt={logo.data.attributes.alternativeText} />
                        </Link>
                    }
                    <div className="rightSide">
                        {/* MENU DESKTOP */}
                        {menu && menu.length > 0 &&
                            <div className="dropdownLinks" ref={wrapperRef}>
                                {menu.map((item, index) => {
                                    if ( index < 6 ) {
                                        return (
                                            <div className={`header-dropdown ${itemActive === index ? 'open' : ''} ${!item?.withDescription ? 'position-relative' : ''}`} key={index} onClick={() => changeActiveItem(index)}>
                                                {/* MAIN LINK ON HEADER */}
                                                {item.title &&
                                                    <div className="text">
                                                        <Text
                                                            type="span"
                                                            font="poppins-medium"
                                                            color="white"
                                                            text={item.title}
                                                            fontSize="font-16"
                                                        />
                                                        <Image src="./images/arrow.svg" alt="arrow" className="arrow" />
                                                        <div className={`arrow-up ${itemActive === index ? 'visible' : ''}`} />
                                                    </div>
                                                }
                                               
                                                {/* OPTIONS */}                                            
                                                {item?.navItem &&
                                                    <div className={`items ${(!item?.withDescription) ? 'simple' : ''}`}>
                                                        <div>
                                                            {/* SIMPLE ITEM */}
                                                            {!item?.withDescription && item?.navItem.length > 0 && item?.navItem.map((link, indexLink) => (
                                                                <Link to={link.url} key={indexLink}>
                                                                    {link.title &&
                                                                        <Text
                                                                            type="span"
                                                                            font="poppins-medium"
                                                                            color="darkGrey"
                                                                            text={link.title}
                                                                            fontSize="font-16"
                                                                        />
                                                                    }
                                                                </Link>                                                                 
                                                            ))}
                                                            {/* COMPLEX ITEM */}
                                                            {item?.withDescription && item.navItem.map((link, indexLink) => (
                                                                indexLink < 6 ?
                                                                    <Link to={link?.url} key={indexLink}>
                                                                        <div>
                                                                            {link?.title &&
                                                                                <Text
                                                                                    type="span"
                                                                                    font="poppins-medium"
                                                                                    color="darkGrey"
                                                                                    text={link?.title}
                                                                                    fontSize="font-16"
                                                                                    maxLines="2"
                                                                                />
                                                                            }
                                                                            {link?.description &&
                                                                                <Text
                                                                                    type="p"
                                                                                    font="roboto-light"
                                                                                    color="darkGrey"
                                                                                    text={link?.description}
                                                                                    fontSize="font-12"
                                                                                    maxLines="3"
                                                                                />
                                                                            }
                                                                            {link?.image && link?.image?.data && link?.image?.data?.attributes && link?.image?.data?.attributes?.url && 
                                                                                <div className="image-container">
                                                                                    <Image src={process.env.REACT_APP_API + link?.image?.data?.attributes?.url} alt={process.env.REACT_APP_API + link?.image?.data?.attributes?.alternativeText} />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </Link>
                                                                : 
                                                                <></>
                                                            ))}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                    else {
                                        return (<></>)
                                    }
                                })}
                            </div>
                        }
                        {/* RIGHT BUTTONS */}
                        <div className="buttons-container">
                            { buttons && buttons.length > 0 && buttons.map((button, index) => {                            
                                if (button.title && button.isForm && index < 1) {
                                    return (                                    
                                        <Button key={index} type="fullColored" bgcolor="bgYellow" textcolor="textWhite poppins" text={button.title} margin='ml16' onClick={() => setIsModalOpen(true)} /> 
                                    )
                                }
                                else if (button.title && !button.isForm && button.url && index < 1) {
                                    return (
                                        <Button type="lineColored" bgcolor="bgYellow" textcolor="textWhite poppins" text={button.title} link={button.url} />
                                    )
                                }
                                else {
                                    return ( <></> )
                                }
                            })}
                        </div>
                        {/* MENU HAMBURGER */}
                        <div className={`hamburger ${menuIsOpen ? 'open' : ''}`} onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <span />
                            <span />
                        </div>
                    </div>
                </div>
            </Container>
            { menu &&
                <Menu menu={menu} open={menuIsOpen} setMenuIsOpen={setMenuIsOpen}/>
            }
            { formModal &&
                 <Modal isOpen={isModalOpen} {...formModal} setIsModalOpen={setIsModalOpen} />
            }
        </header>

    )
}