export default function Text (props) {

    const {
        className ='',
        type = 'p',
        font = 'roboto-medium',
        color = 'white',
        fontSize = 'font-24',
        text = '',
        maxLines = ''
    } = props;


    if (type === 'h1' ) {
        return (
            <h1 className={`${className} ${type} ${font} ${color} ${fontSize} maxLines-${maxLines}`}> {text} </h1>
        )
    }

    else if (type === 'h2') {
        return (
            <h2 className={`${className} ${type} ${font} ${color} ${fontSize} maxLines-${maxLines}`}> {text} </h2>
        )
    }

    else if (type === 'h3') {
        return (
            <h3 className={`${className} ${type} ${font} ${color} ${fontSize} maxLines-${maxLines}`}> {text} </h3>
        )
    }

    else if (type === 'p') {
        return (
            <p className={`${className} ${type} ${font} ${color} ${fontSize} maxLines-${maxLines}`}> {text} </p>
        )
    }

    else if (type === 'span') {
        return (
            <span className={`${className} ${type} ${font} ${color} ${fontSize} maxLines-${maxLines}`}> {text} </span>
        )
    }

    else {
        return (<></>)
    }
}