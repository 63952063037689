import SliderCategories from "../../molecules/sliderCategories";

export default function CategoriesList (props) {

    const {
        isNews,
        isCaseStudies,
        newItemActive,
        setNewItemActive,
        caseStudyItemActive,
        setCaseStudyItemActive
    } = props;


    return ( 

        <section className="categoriesList margin-sectionTop">
            <SliderCategories 
                {...props} 
                isNews={isNews} 
                isCaseStudies={isCaseStudies}
                newItemActive={newItemActive}
                setNewItemActive={setNewItemActive}
                caseStudyItemActive={caseStudyItemActive} 
                setCaseStudyItemActive={setCaseStudyItemActive}
            />
        </section>

    )
}