import { useEffect, useState, useMemo } from "react";

import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

import Container from "../../atoms/container";
import Image from "../../atoms/image";
import Text from "../../atoms/text";
import Button from "../../atoms/button";
import LanguageDropdown from "../../molecules/languagesDropdown";


export default function Footer({data,locales, currentLang, onLanguageChange}) {

    
    const dropdownOptions = useMemo(() => {
        return locales && locales.map(locale => ({
          value: locale.code,
          label: locale.name,
        }));
      }, [locales]);

    const [showButton, setShowButton] = useState(false);
    const [error, setError] = useState(null);

    const listenScrollEvent = () => {
        if (window.scrollY <= 500) {
            setShowButton(false)
        } else if (window.scrollY > 500) {
            setShowButton(true)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () => window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    const scrollTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    return (
        <footer className="footer">
            <div className={`scrollTop ${showButton ? 'show' : 'hide'}`} onClick={scrollTop}>
                <Button
                    type="fullColored bold"
                    bgcolor="bgWhite"
                    textcolor="textYellow"
                    arrowRightYellow
                />
            </div>
            <div className="topRow">
                <Container size="lg">
                    {data?.attributes?.logo?.data?.attributes?.url &&
                        <Image src={process.env.REACT_APP_API + data?.attributes?.logo?.data?.attributes?.url} alt={data?.attributes?.logo?.data?.attributes?.alternativeText} />
                    }
                    { dropdownOptions &&
                        <LanguageDropdown locales={dropdownOptions} currentLang={currentLang} onLanguageChange={onLanguageChange} />
                    }
                </Container>
            </div>
            <div className="bottomRow">
                <Container size="lg">
                    <div>
                        <div className="links-container">
                            {data?.attributes?.links_to.map((link, index) => {
                                return (
                                    link.title && link.url &&
                                    <Link to={link.url} key={index}>
                                        <Text
                                            type="span"
                                            font="poppins-regular"
                                            color="white"
                                            text={link.title}
                                            fontSize="font-16"
                                        />
                                    </Link>
                                )
                            })}
                            <Text
                                className="copyright"
                                type="span"
                                font="poppins-light"
                                color="white"
                                text={`\u00A9 Copyright ${new Date().getFullYear()}. Softi9`}
                                fontSize="font-16"
                            />
                        </div>
                        <div className="socialmedia-container">
                            {data?.attributes?.socialMedia.map((item, index) => {
                                return (item?.url && item?.image?.data?.attributes?.url &&
                                    <Link key={index} to={item.url} target="_blank" rel="noopener noreferrer">
                                        <ReactSVG src={process.env.REACT_APP_API + item.image.data.attributes.url} />
                                    </Link>
                                )}
                            )}
                        </div>
                    </div>
                    <div className="images">
                        {data?.attributes?.fundingLogo?.data.map((item, index) => {
                            return (
                                <Image key={index} src={process.env.REACT_APP_API + item?.attributes?.url} alt={item?.attributes?.alternativeText} />
                            )
                        }
                        )}
                    </div>
                </Container>
            </div>
        </footer>
    )
}