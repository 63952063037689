import Container from "../../atoms/container";
import Text from "../../atoms/text";
import Image from "../../atoms/image";
import TextBox from "../../molecules/textBox";


export default function IntroSectionImage (props) {

    const {
        bg = "dark",
        mb24,
        firstSection
    } = props


    const title = props?.title;
    const description = props?.description; 
    const pageTitle = props?.pageTitle;
    const background_image =  props?.background_image;
    
    
    if (!props) {
        return <></>;
    }

    return ( 
        <section className={`introSectionImage bg-${bg}  ${!mb24 && "margin-sectionBottom"}`}>
            <div className={`title-container ${background_image?.data?.attributes?.url && 'bgImage'} ${props.smallHeight && 'smallHeight'} ${firstSection && 'margin-firstSectionTop'}`}>
                {background_image?.data?.attributes?.url &&
                    <Image src={process.env.REACT_APP_API+background_image.data.attributes.url} alt={'background image'}/>
                }
                <Container size="md">
                    <div className="text-container">
                        {pageTitle &&
                            <Text 
                                type="h3"
                                font="poppins-medium"
                                fontSize="font-28"
                                color="yellow"
                                text={pageTitle}
                                maxLines="2"
                            />
                        }
                        { title &&
                            <Text 
                                className={`title ${props.marginTitle ? 'mb' : ''}`}
                                type="h1"
                                font="poppins-medium"
                                fontSize="font-66"
                                color={`${description ? "white mb24" : "white"}`}
                                text={title}
                                maxLines="3"
                            />
                        }
                        { description &&
                            <TextBox
                                text={description}
                            />
                        }
                    </div>
                </Container>
            </div>
        </section>

    )
}