import Text from "../../atoms/text";
import Container from "../../atoms/container";
import Image from "../../atoms/image";
import { Link } from "react-router-dom";


export default function PartnershipsDetail(props) {

    const {
        partners
    } = props;
    

    if (!props) {
        return <></>
    }

    return (
        <section className="partnershipsDetail margin-sectionTop margin-sectionBottom">
            <Container size="md">
                { partners && partners.length > 0 && partners.map((partner, index) => (
                    <div className="content" key={index}>
                        { partner?.title &&
                            <Text 
                                type="h1"
                                font="poppins-medium"
                                fontSize="font-40"
                                color="white"
                                text={partner.title}
                                maxLines="2"
                            />
                        }

                        <div className="list">
                            { partner?.section && partner?.section.map((item, index) => {
                                if (item.url) {
                                    return (
                                        <div key={index} className="partner">                                     
                                            <Link to={item.url} target="_blank" rel="noopener noreferrer">
                                                {item?.image && item?.image?.data && item?.image?.data?.attributes && item?.image?.data?.attributes?.url &&
                                                    <div className="image-container">
                                                        <Image src={ process.env.REACT_APP_API + item?.image?.data?.attributes?.url } alt="logo"/>
                                                    </div>
                                                }
                                                {item?.title &&
                                                    <Text 
                                                        type="span"
                                                        font="poppins-light"
                                                        fontSize="font-20"
                                                        color="white"
                                                        text={item.title}
                                                        maxLines="2"
                                                        className="name"
                                                    />
                                                }
                                                {item?.region &&
                                                    <Text 
                                                        type="span"
                                                        font="poppins-light"
                                                        fontSize="font-20"
                                                        color="white"
                                                        text={item.region}
                                                        maxLines="2"
                                                    />
                                                }
                                            </Link>  
                                        </div>
                                    )
                                } 
                                
                                else {
                                    return (
                                        <div key={index} className="partner">   
                                            {item?.image && item?.image?.data && item?.image?.data?.attributes && item?.image?.data?.attributes?.url &&
                                                <div className="image-container">
                                                    <Image src={ process.env.REACT_APP_API + item?.image?.data?.attributes?.url } alt="logo"/>
                                                </div>
                                            }
                                            {item?.title &&
                                                <Text 
                                                    type="span"
                                                    font="poppins-light"
                                                    fontSize="font-20"
                                                    color="white"
                                                    text={item.title}
                                                    maxLines="2"
                                                    className="name"
                                                />
                                            }
                                            {item?.region &&
                                                <Text 
                                                    type="span"
                                                    font="poppins-light"
                                                    fontSize="font-20"
                                                    color="white"
                                                    text={item.region}
                                                    maxLines="2"
                                                />
                                            }
                                        </div>  
                                    )
                                }     
                            })}
                        </div>

                        {/*<div className="buttons-container">
                            <Button 
                                type="fullColored"
                                bgcolor="bgYellow"
                                textcolor="textWhite poppins"
                                text={'Request a Demo'}
                            />
                        </div> */}
                    </div>
                ))}
            </Container>
        </section>
    );
}