import { useState, useEffect } from "react";
import ProjectDetail from "../components/sections/projectDetail";
import IntroSection from "../components/sections/introSection";




export default function Projects({currentLang,setHeaderDarkMode}) {

    /* HEADER DARKMODE */

    useEffect(() => {
        setHeaderDarkMode(true);
    }, []);

    /* **************** */

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/projectsandfundingpage?locale=${currentLang}&populate=deep`)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    if (loading) return <div className="loadingPage"></div>;

    return (
        <>
            {data?.data?.attributes?.banner &&
                <IntroSection bg="white" {...data?.data?.attributes?.banner} firstSection/>
            }
            {data?.data?.attributes &&
                <ProjectDetail data={data?.data?.attributes} />
            }
        </>
    )
}