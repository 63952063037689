import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";


import IntroSectionProduct from "../components/sections/introSectionProduct";
import Checklist from "../components/sections/checklist";
import Statistics from "../components/sections/statistics";


export default function ProductDetails({currentLang, setIsModalOpen,setHeaderDarkMode}) {

    /* HEADER DARKMODE */
  
    useEffect(() => {
        setHeaderDarkMode(false);
      }, []);
      
    /* **************** */

    
    const { slug }=useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    
    useEffect(() => {
        if (!slug || !currentLang) return;
        let url = process.env.REACT_APP_API + `/api/products?filters[slug][$eq]=${slug}&publicationState=${process.env.REACT_APP_PREVIEW}&[populate]=deep&locale=${currentLang}`
        
        const fetchData = async () => {
            try {
                const response = await fetch(url)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug, currentLang]);

    //Redirect in case wrong slug
     useEffect(() => {
        if (data && data.data.length === 0) {
          navigate("/");
        }
      }, [data, navigate]);


    if (loading) return <div className="loadingPage"></div>;

    return (
        <>
            { data?.data.length > 0 && data.data[0]?.attributes &&
                <IntroSectionProduct
                    {...data.data[0].attributes}
                    setIsModalOpen={setIsModalOpen}
                    firstSection
                    productPage
                />
            }
            {/* STATISTICS */}
            { data?.data[0]?.attributes?.statistics &&
                <Statistics {...data?.data[0]?.attributes?.statistics}/>
            }
            {/* SECTION  ONLY VISIBLE IF THERE'S CONTENT IN COLUMNS OF CHECKLIST */}
            { data?.data[0]?.attributes?.featuresChecklist &&
                <Checklist content= {data?.data[0]?.attributes?.featuresChecklist} />
            }

            

            {/* <TextButtons 
                {...dataTextButtons}
                setIsModalOpen={setIsModalOpen}
            /> */}
        </>
    )
}