import Container from "../../atoms/container";
import Text from "../../atoms/text";
import Button from "../../atoms/button";
import Image from "../../atoms/image";
import TextBox from "../../molecules/textBox";
import StatisticsBox from "../../molecules/statisticsBox";
import { Link } from "react-router-dom";


export default function IntroSectionProduct (props) {

    const {
        bg = "dark",
        background_image,
        pageTitle,
        title,
        bold_description,
        description,
        button,
        setIsModalOpen,
        noMargin,
        firstSection,
        productPage
    } = props

    
    if (!props) {
        return <></>;
    }
   
   
    return ( 
        <section className={`introSectionProduct bg-${bg} margin-sectionBottom`}>
            <div className={`title-container 
                ${background_image?.data?.attributes?.url && 'bgImage'} 
                ${firstSection && 'margin-firstSectionTop'} 
                smallHeight`}>
                
                {/* BACKGROUND IMAGE */}
                {background_image?.data?.attributes?.url &&
                    <Image src={process.env.REACT_APP_API+background_image.data.attributes.url} alt={'background image'}/>
                }
                <Container size="md">
                    <div className="text-container">
                        {/* PAGE TITLE */}
                        {pageTitle &&
                            <Text 
                                type="h3"
                                font="poppins-medium"
                                fontSize="font-28"
                                color="yellow"
                                text={pageTitle}
                                maxLines="2"
                            />
                        }
                        {/* TITLE */}
                        { title &&
                            <Text 
                                className={`title ${noMargin ? 'noMargin' : 'mb'}`}
                                type="h1"
                                font="poppins-medium"
                                fontSize="font-66"
                                color={"white"}
                                text={title}
                                maxLines="3"
                            />
                        }
                        {/* DESCRIPTION */}
                        { bold_description &&
                            <Text 
                                type="p"
                                font="poppins-medium"
                                fontSize="font-32"
                                color="yellow"
                                text={bold_description}
                                maxLines="10"
                            />
                        }
                        {/* DESCRIPTION */}
                        { description && productPage &&
                            <TextBox
                                text={description}
                                textLight
                            />
                        }
                        { description && !productPage &&
                            <Text 
                                type="p"
                                font="poppins-light"
                                fontSize="font-24"
                                color="lightGrey"
                                text={description}
                                maxLines="10"
                            />
                        }
                        { button && button.isForm && button.title &&
                            <Button type="fullColored" bgcolor="bgYellow" textcolor="textWhite poppins" text={button.title} onClick={() => setIsModalOpen(true)} />
                        }
                        { button && !button.isForm && button.title && button.url &&
                            <Button type="fullColored" bgcolor="bgYellow" textcolor="textWhite poppins" text={button.title} link={button.url} />
                        }
                    </div>
                </Container>
            </div>
        </section>

    )
}