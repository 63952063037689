import Container from "../../atoms/container";
import Accordion from "../../molecules/accordion";


export default function FaqsList(props) {

    const {
        questions
    } = props;

    
    if (!props) {
        return <></>;
    }

    return (

        <section className="faqsList margin-sectionBottom">
            <Container size="md">
                {questions && questions.length > 0 &&
                    <div className="accordion-container">
                        {questions.map((item, index) => {
                            return (
                                <Accordion key={index} {...item} />
                            )
                        })}
                    </div>
                }
            </Container>
        </section>

    )
}