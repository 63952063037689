import { Link } from "react-router-dom";



export default function Button (props) {

    const {
        type = "",
        bgcolor = "",
        textcolor = "",
        text = "",
        margin = "",
        scrollUp,
        arrowRight,
        arrowRightYellow,
        onClick,
        link,
        file,
        page
    } = props
    

    if (scrollUp) {
        return (
            <button className={`rounded ${type} ${bgcolor} ${textcolor} ${margin}`}>
                <img src="./images/yellow_arrow_button.svg" alt="arrow"/>
            </button>
        )
    }

    else if (arrowRight) {
        if (page) {
            return (
                <Link to={page}>
                    <button className="arrowRight">
                        <img src="./images/arrowRight-button.svg" alt="button"/>
                    </button>
                </Link>
            )
        }
        else if (file) {
            return (
                <a href={file} target="_blank" rel="noopener noreferrer">
                    <button className="arrowRight">
                        <img src="./images/arrowRight-button.svg" alt="button"/>
                    </button>
                </a>
            )
        }
        else if (link) {
            return (
                <Link to={link} target="_blank" rel="noopener noreferrer">
                    <button className="arrowRight">
                        <img src="./images/arrowRight-button.svg" alt="button"/>
                    </button>
                </Link>
            )
        }
        else {
            return (
                <button className="arrowRight" onClick={onClick}>
                    <img src="./images/arrowRight-button.svg" alt="button"/>
                </button>
            )
        }        
    }

    else if (arrowRightYellow) {
        if (page) {
            return (
                <Link to={page}>
                    <button className={`arrowRightYellow rounded ${type} ${bgcolor} ${textcolor} ${margin}`}>
                        <img src="./images/yellow_arrow_button.svg" alt="button"/>
                    </button>
                </Link>
            )
        }
        else if (file) {
            return (
                <a href={file} target="_blank" rel="noopener noreferrer">
                    <button className={`arrowRightYellow rounded ${type} ${bgcolor} ${textcolor} ${margin}`}>
                        <img src="./images/yellow_arrow_button.svg" alt="button"/>
                    </button>
                </a>
            )
        }
        else if (link) {
            return (
                <Link to={link} target="_blank" rel="noopener noreferrer">
                    <button className={`arrowRightYellow rounded ${type} ${bgcolor} ${textcolor} ${margin}`}>
                        <img src="./images/yellow_arrow_button.svg" alt="button"/>
                    </button>
                </Link>
            )
        }
        else {
            return (
                <button className={`arrowRightYellow rounded ${type} ${bgcolor} ${textcolor} ${margin}`}>
                    <img src="./images/yellow_arrow_button.svg" alt="button"/>
                </button>
            )
        }
    }

    else {
        if (page) {
            return (
                <Link to={page}>
                    <button className={`${type} ${bgcolor} ${textcolor} ${margin}`}>
                        {text}
                    </button>
                </Link>
            )
        }
        else if (file) {
            return (
                <a href={file} target="_blank" rel="noopener noreferrer">
                    <button className={`${type} ${bgcolor} ${textcolor} ${margin}`}>
                        {text}
                    </button>
                </a>
            )
        }
        else if (link) {
            return (
                <Link to={link} target="_blank" rel="noopener noreferrer">
                    <button className={`${type} ${bgcolor} ${textcolor} ${margin}`}>
                        {text}
                    </button>
                </Link>
            )
        }
        else {
            return (
                <button className={`${type} ${bgcolor} ${textcolor} ${margin}`} onClick={onClick}>
                    {text}
                </button>
            )
        }
    }
}