import Image from "../../atoms/image";
import StatisticsBox from "../../molecules/statisticsBox";
import Container from "../../atoms/container";


export default function Statistics (props) {

    const {
        image,
        item,
        fullDark,
        noImage
    } = props;


    if (!props) return <></>

    
    return (
        <section className={`statistics ${fullDark && 'fullDark'}`}>
            <Container size="md"> 
            {image && image?.data?.attributes?.url && !noImage &&            
                <div className="image-container">
                    <Image src={process.env.REACT_APP_API + image?.data?.attributes?.url} alt={'statistics image'}/>
                </div>
            }
            {item && item.length > 0 &&
                <div className="info-container">
                    <div>
                        { item.map((item, index) => (
                            <StatisticsBox {...item} key={index} fullDark={fullDark}/>
                        ))} 
                    </div>
                </div>  
            }       
            </Container>      
        </section>
    );
}