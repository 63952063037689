import { useState, useEffect } from "react";

import Banner from "../components/sections/banner";
import Products from "../components/sections/products";
import Ecosystem from "../components/sections/ecosystem";
import Testimonials from "../components/sections/testimonials";
import TrustedBy from "../components/sections/trustedBy";



export default function Homepage({setHeaderDarkMode, currentLang}) {

    useEffect(() => {
      setHeaderDarkMode(false);
    }, []);

  /* **************** */

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  let url = process.env.REACT_APP_API + '/api/homepage?populate=deep' + `&locale=${currentLang}`

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) return <div className="loadingPage"></div>;
  
  return (
    <>
      {data?.attributes?.slider &&
        <Banner {...data.attributes} firstSection/>
      } 
      {data?.attributes?.trustedBy &&
        <TrustedBy {...data.attributes.trustedBy}/>
      }
      {data?.attributes?.ecosystem &&
        <Ecosystem {...data.attributes.ecosystem} />
      }
      {data?.attributes?.products &&
        <Products products={data.attributes.products} />
      }     
      {data?.attributes?.testimonials &&
        <Testimonials {...data.attributes.testimonials} />
      }
    </>
  )
}