import { useState, useEffect } from "react";

import IntroSection from "../components/sections/introSection";
import FaqsList from "../components/sections/faqsList";

export default function Faqs({currentLang,setHeaderDarkMode}) {

  /* HEADER DARKMODE */

    useEffect(() => {
      setHeaderDarkMode(false);
    }, []);

  /* **************** */

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/faqspage?locale=${currentLang}&populate=deep`)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  
  if (loading) return <div className="loadingPage"></div>;

  return (
    <>
      { data?.data?.attributes?.banner &&
        <IntroSection {...data?.data?.attributes?.banner} mb24 firstSection/>
      }
      { data?.data?.attributes?.questions &&
        <FaqsList {...data?.data?.attributes} />
      }
    </>
  )
}