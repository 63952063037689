import Container from "../../atoms/container";
import Text from "../../atoms/text";
import TextBox from "../../molecules/textBox";


export default function SimpleTextBlock (props) {

    const {
        bg = 'bg-light',
        introduction,
        mb24,
    } = props

    
    if (!props) {
        return <></>;
    }

    if (introduction && (introduction?.title || introduction?.description)) {
        return ( 
            <section className={`simpleTextBlock margin-sectionTop`}>
                <Container size='md'>
                    <div className='text-container'>    
                        {introduction?.title &&
                            <Text 
                                className="title"
                                type="h3"
                                font="poppins-medium"
                                fontSize="font-32"
                                color="yellow"
                                text={introduction?.title}
                                maxLines="10"
                            />
                        }  
                        {introduction?.description &&    
                            <TextBox
                                text={introduction?.description}
                            />
                        }
                    </div> 
                </Container> 
            </section>
        )
    }
    else { 
        return (<></>)
    }
}