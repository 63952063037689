import Container from "../../atoms/container";
import Text from "../../atoms/text";
import CheckItem from "../../molecules/checkItem";

{/* SECTION WITH LIST OF FEATURES IN PRODUCTS */}
export default function Checklist (props) {

    const {
        content
    } = props;

    if (!props) {
        return <></>;
    }

    
    return ( 

        <section className="checklist margin-sectionBottom margin-sectionTop bgWhite">
            <Container size="md">
            
                {/* TITLE OF LIST */}
                { content && content.title &&
                    <Text 
                        type="h3"
                        font="poppins-medium"
                        fontSize="font-36"
                        color="yellow"
                        text={content.title}
                        maxLines="2"
                    />
                }

                {/* LIST OF FEATURES */}
                <div className="list-container">
                    {/* LEFT COLUMN */}
                    {content && content.leftColumn &&
                        <div>
                            {content.leftColumn.map((item, index) => (
                                <CheckItem key={index} {...item} checkImage={content?.icon?.data?.attributes}/>
                            ))}
                        </div> 
                    }
                    {/* RIGHT COLUMN */}
                    {content && content.rightColumn &&
                    <div>
                        {content.rightColumn.map ((item, index) => (
                            <CheckItem key={index} {...item} checkImage={content?.icon?.data?.attributes}/>
                        ))}
                    </div> 
                    }
                </div>
                
            </Container>
        </section>

    )
}