import {useState} from "react";

import Container from "../../atoms/container";
import Text from "../../atoms/text";
import { Link } from "react-router-dom";


export default function CatalogueList (props) {

    const {
       bg,
       data
    } = props;

    {/* CATALOGUE CATEGORY CLICKED - OPEN | CLOSE */}
    const [categoryActive, setCategoryActive] = useState(-1);
    
    const changeCategoryState = (index) => {
        if (index === categoryActive) {
            setCategoryActive(-1);
        }
        else {
            setCategoryActive(index);
        }
    }

    {/* CATALOGUE FILE CLICKED - OPEN | CLOSE */}
    const [fileActive, setFileActive] = useState(-1);
    
    const changefileState = (index) => {
        if (index === fileActive) {
            setFileActive(-1);
        }
        else {
            setFileActive(index);
        }
    }

    
    if (!props) {
        return <></>
    }

    return ( 
        <section className={`catalogueList ${bg ? 'bg-'+ bg : ''} margin-sectionBottom margin-small-sectionTop`}>
            <Container size="md">  
                <div className="catalogueList-content">        
                    { data && data.length > 0 && data.map((category, index) => (
                        <div key={index} className='category-box'>
                            {/* CATEGORY NAME - BOX OPEN | CLOSE */}
                            <div className={`title-container ${categoryActive === index ? 'open' : 'closed'}`} onClick={() => changeCategoryState(index)}>
                                <Text 
                                    type="h2"
                                    font="poppins-regular"
                                    fontSize="font-32"
                                    color="lighterGrey"
                                    text={category?.attributes?.category}
                                    maxLines="3"
                                />
                                <div className="button" onClick={changeCategoryState}>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                            <div className="files-container">
                                {/* FILE NAME - BOX OPEN | CLOSE */}
                                {category?.attributes?.document && category?.attributes?.document.length > 0 && category?.attributes?.document.map((document, docIndex) => (
                                    <div className='fileContent' key={docIndex}>
                                        <div className={`fileName ${fileActive === docIndex ? 'open' : 'closed'}`} onClick={() => changefileState(docIndex)}>
                                            {document?.title &&
                                                <Text 
                                                    type="h3"
                                                    font="poppins-regular"
                                                    fontSize="font-24"
                                                    color="lighterGrey"
                                                    text={document?.title}
                                                    maxLines="3"
                                                />
                                            }
                                        </div>
                                        <div className="fileVersions">
                                            {document && document?.doc && document?.doc.length > 0 && document?.doc.map((langFile, langIndex) => ((
                                                <div className="langFiles-container" key={langIndex}>
                                                    {langFile?.doc?.data?.attributes?.url &&
                                                        <Link to={process.env.REACT_APP_API + langFile?.doc?.data?.attributes?.url} target="_blank" rel="noopener noreferrer">
                                                            <div className="fileLinks">
                                                                {/* FILE LANGUAGE */}
                                                                {langFile?.language &&
                                                                    <Text 
                                                                        type="span"
                                                                        font="poppins-regular"
                                                                        fontSize="font-18"
                                                                        color="lighterGrey"
                                                                        text={langFile?.language}
                                                                        maxLines="2"
                                                                    />
                                                                }
                                                                {/* FILE UPLOAD DATE */}
                                                                {langFile?.doc?.data?.attributes?.updatedAt &&
                                                                    <Text 
                                                                        type="span"
                                                                        font="poppins-regular"
                                                                        fontSize="font-13"
                                                                        color="lighterGrey"
                                                                        text={new Date(langFile?.doc?.data?.attributes?.updatedAt).toLocaleDateString('en-GB').replace(/\//g, '/')}
                                                                        maxLines="2"
                                                                    />
                                                                }
                                                                {/* FILE SIZE */}
                                                                {langFile?.doc?.data?.attributes?.size &&
                                                                    <Text 
                                                                        type="span"
                                                                        font="poppins-regular"
                                                                        fontSize="font-13"
                                                                        color="lighterGrey"
                                                                        text={"   - " + (langFile?.doc?.data?.attributes?.size/1000).toFixed(2) +  'MB'}
                                                                        maxLines="2"
                                                                    />
                                                                }
                                                            </div>
                                                        </Link> 
                                                    }
                                                </div>
                                            )))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>  
            </Container>
        </section>

    )
}