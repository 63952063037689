import Text from "../../atoms/text";
import Container from "../../atoms/container";
import Image from "../../atoms/image";
import { Link } from "react-router-dom";


export default function Ecosystem (props) {


    const {
        image,        
        links,
        description, 
        highlights
    } = props;


    if (!props) {
        return <></>;
    }

    return ( 

        <section className="introducing margin-sectionBottom">
            <Container size="md">       
                <div className="image-container"> 
                    {props?.title &&
                        <Text 
                            className="image-title"
                            type="h2"
                            font="poppins-semibold" 
                            color="yellow"
                            fontSize="font-66" 
                            text={props.title}
                            maxLines="4"
                        />
                    }
                    {/* IMAGE */}
                    {image && image?.data && image?.data?.attributes && image?.data?.attributes?.url &&
                        <div className="image">
                            <Image src={process.env.REACT_APP_API + image?.data?.attributes?.url} alt={'CUCo image'} />  
                            {links &&
                                <div className="image-interactive-container">
                                    { links.length > 0 && links[0] && links[0]?.text &&
                                        <Link to={links[0].text}>
                                            <div className="linkTop"></div>
                                        </Link>
                                    }
                                    { links.length > 0 && links[1] && links[1]?.text &&
                                        <Link to={links[1].text}>
                                            <div className="linkLeft"></div>
                                        </Link>
                                    }
                                    { links.length > 0 && links[2] && links[2]?.text &&
                                       <Link to={links[2].text}>
                                            <div className="linkRight"></div>
                                        </Link>
                                    }
                                </div>
                            }
                        </div>            
                    }
                </div>

                {/* DESCRIPTION TEXT */}
                {description &&
                    <Text 
                        className="description"
                        type="p"
                        font="poppins-regular" 
                        color="yellow"
                        fontSize="font-32" 
                        text={description}
                        maxLines=""
                    />
                }
                {highlights &&
                    <div className="list-container">
                        { highlights.length > 0 && highlights.map((item, index) => (
                            item?.text &&
                            <div key={index}>
                                 <Text 
                                    type="p"
                                    font="roboto-light" 
                                    color="lightGrey"
                                    fontSize="font-22"
                                    text={item.text}
                                    maxLines='3'
                                />
                            </div>
                        ))}
                    </div>
                }          
            </Container>
        </section>

    )
}