import Image from "../../atoms/image";
import Text from "../../atoms/text";
import Button from "../../atoms/button";
import Container from "../../atoms/container";
import { Link } from "react-router-dom";


export default function BlocksLinkList(props) {

    const {
        bg = "dark",
        list,
        noMarginBottom
    } = props

    
    if (!props) {
        return <></>;
    }

    return (

        <section className={`bg-${bg} blocksLinkList margin-sectionTop ${noMarginBottom ? '' : 'margin-sectionBottom'}`}>

            <Container size="md">                
                <div className="complexList margin-sectionBottom">
                    {list && list.map((item, index) => {
                        {/* LINK - IMAGE BLOCK - CASE STUDIES */}
                        return (
                            <Link to={item?.attributes?.slug} key={index}>
                                <div className="item" key={index}>
                                    {/* IMAGE */}
                                    <div className="image-container">
                                        {/* CASE STUDY */}
                                        {item?.attributes && item?.attributes?.thumbnail_image && 
                                            item?.attributes?.thumbnail_image?.data &&  item?.attributes?.thumbnail_image?.data?.attributes &&
                                            item?.attributes?.thumbnail_image?.data?.attributes?.url &&
                                                <Image src={process.env.REACT_APP_API + item?.attributes?.thumbnail_image?.data?.attributes?.url} alt='image' />
                                        }
                                        {/* OPACITY FILTER */}
                                        <div className="colorHover"></div>

                                        { /* BUTTONS INSIDE IMAGE BLOCK */}
                                        <div className="bottom-content">
                                            
                                            {/* CATEGORY - NOT SHOWING */}
                                            { /*item.category &&
                                                <div className="category-container">
                                                    <Text
                                                        type="span"
                                                        font="poppins-regular"
                                                        fontSize="font-32"
                                                        color="white"
                                                        text={item.category}
                                                        maxLines="2"
                                                    />
                                                </div>
                                            */}

                                            <Button arrowRight />
                                        </div>
                                    </div>

                                    {/* TITLE */}

                                    {/* TITLE CASE STUDY */}
                                    { item.attributes && item.attributes.banner && item.attributes.banner.title &&
                                        <Text
                                            type="h3"
                                            font="poppins-semibold"
                                            fontSize="font-40"
                                            color="white"
                                            text={item.attributes.banner.title}
                                            maxLines="2"
                                        />
                                    }
                                    {/* TITLE NEW */}
                                    { item.attributes && item.attributes.title &&
                                        <Text
                                            type="h3"
                                            font="poppins-semibold"
                                            fontSize="font-40"
                                            color="white"
                                            text={item.attributes.title}
                                            maxLines="2"
                                        />
                                    }

                                    {/* DESCRIPTION */}

                                    {/* DESCRIPTION CASE STUDY */}
                                    { item.attributes && item.attributes.banner && item.attributes.banner.description &&
                                        <Text
                                            type="span"
                                            font="poppins-regular"
                                            fontSize="font-20"
                                            color="white"
                                            text={item.attributes.banner.description}
                                            maxLines="3"
                                        />
                                    }
                                    
                                    {/* DESCRIPTION NEW */}
                                    { item.attributes && item.attributes.summary  && 
                                        <Text
                                            type="span"
                                            font="poppins-regular"
                                            fontSize="font-20"
                                            color="white"
                                            text={item.attributes.summary}
                                            maxLines="3"
                                        />
                                    }

                                </div>
                            </Link>
                        )
                    })}
                </div>            
            </Container>

        </section>

    )
}