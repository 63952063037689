import Text from "../../atoms/text";
import Container from "../../atoms/container";
import SliderCategories from "../../molecules/sliderDeviceModels";


export default function DevicesCompatibilityDetail(props) {

    const {
        header,
        devices
    } = props;

    
    if (!props) {
        return <></>;
    }

    return (
        <section className="devicesCompatibilityDetail margin-sectionBottom">
            {header &&
                <Container size="md">
                    <div className="content">
                        <Text 
                            type="h1"
                            font="poppins-medium"
                            fontSize="font-52"
                            color="white"
                            text={header}
                            maxLines="3"
                        />
                    </div>
                </Container>
            }
            { devices && devices.length > 0 &&
                <Container size="md">
                    <div className="content">
                        <SliderCategories slider={devices} />
                    </div>
                </Container>
            }
        </section>
    );
}