import { useState, useEffect } from 'react';

import PartnershipsDetail from '../../components/sections/partnershipsDetail';
import IntroSection from '../../components/sections/introSection';

export default function Partnerships({currentLang,setHeaderDarkMode}) {

    /* HEADER DARKMODE */

    useEffect(() => {
        setHeaderDarkMode(false);
    }, []);

    /* **************** */

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/partnershipspage?populate=deep&locale=${currentLang}`)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div className='loadingPage'></div>;

    return (
        <>
            {data?.data?.attributes?.banner &&
                <IntroSection {...data?.data?.attributes?.banner} mb0 firstSection/>
            }
            {data?.data?.attributes?.partners &&
                <PartnershipsDetail {...data?.data?.attributes && data?.data?.attributes} />
            }
        </>
    )
}