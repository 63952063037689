import { useEffect, useRef, useState } from "react";

export default function LanguageDropdown({ locales, currentLang, onLanguageChange }) {

    /* CLICK OUTSIDE */
    function useOutsideAlert(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setMenuOptionsOpen(false)
            }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlert(wrapperRef);

    const [menuOptionsOpen, setMenuOptionsOpen] = useState(false);

    const getCurrentLangName = (lang) => {
        const language = locales.find(item => item.value === lang);
        const langName = language.label;
        return langName
    }

    const clickLanguage = (value) => {
        if (value) {
            onLanguageChange(value);
            setMenuOptionsOpen(false)
        }
    }


    if (!locales) {
        return <div className="loadingPage"></div>;
    }

    return (
        <>
            {locales && currentLang && onLanguageChange &&
                <div className="languages-dropdown" ref={wrapperRef}>
                    <div className={`footer-language-dropdown ${menuOptionsOpen ? 'open' : ''}`} onClick={() => setMenuOptionsOpen(!menuOptionsOpen)}>
                        <span>{getCurrentLangName(currentLang)}</span>
                    </div>
                    <div className={`footer-language-options ${menuOptionsOpen ? 'open' : ''}`}>
                        <div>
                            {locales && locales.map((locale, index) => (
                                <div className={`option ${locale?.label === getCurrentLangName(currentLang) ? 'is-selected' : ''}`} key={index}
                                        onClick={() => clickLanguage(locale.value)}>
                                    <span>{locale?.label}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
