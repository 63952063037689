import { ReactSVG } from "react-svg";

import Image from "../../atoms/image";
import Container from "../../atoms/container";
import Text from "../../atoms/text";


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import Button from "../../atoms/button";


export default function Banner(props) {

    const {
        slider,
        firstSection
    } = props;


    if (!props) {
        return <></>;
    }

    return (
        <section className="banner margin-sectionBottom">
            {slider &&
                <Swiper
                    loop={true}
                    autoplay={{
                        delay: 7000,
                        stopOnLastSlide: false,
                    }} 
                    speed={1000}
                    className='swiper-transition' 
                    direction='horizontal' 
                    pagination={{
                        clickable: true,
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }}
                    modules={[Pagination, Navigation, Autoplay]}
                >
                    {/* SLIDERS */}
                    {slider?.length > 0 && slider.map((item, index) => {
                        return(
                            <SwiperSlide key={index} >
                                 { /* BACKGROUND IMAGE */}
                                { item && item?.background_image?.data?.attributes?.url &&
                                    <Image src={process.env.REACT_APP_API + item.background_image.data.attributes.url} alt={process.env.REACT_APP_API + item.background_image.data.attributes.alternativeText} className='backgroundImage' />
                                }

                                { firstSection && <div className="margin-firstSectionTop" /> }
                                <Container size="md">
                                    <div className="text-container">
                                        <div>
                                            {/* TITLE */}
                                            {item?.title &&
                                                <Text
                                                    type="h1"
                                                    font="poppins-medium"
                                                    color="white"
                                                    text={item?.title}
                                                    maxLines="3"
                                                    fontSize="font-52"
                                                />
                                            }
                                            {/* BUTTON */}
                                            {item?.button?.url &&
                                                <div className="buttons-container">                                                  
                                                    <Button
                                                        type="lineColored bold"
                                                        bgcolor="bgYellow"
                                                        textcolor="textYellow"
                                                        text={item?.button?.text}
                                                        page={item?.button?.url}
                                                    />                                    
                                                </div>
                                            }
                                        </div>
                                        {/* DESCRIPTION */}
                                        <div className="descriptionButtons-container">
                                            {item?.description &&
                                                <Text
                                                    className="description"
                                                    type="p"
                                                    font="roboto-light"
                                                    color="white"
                                                    text={item?.description}
                                                    maxLines="5"
                                                    fontSize="font-24"
                                                />
                                            }
                                        </div>

                                        {/* SMALL RIGHT TEXT */}
                            { item?.header_text &&
                                <div className="info">
                                    { item?.header_text && item?.header_text.title &&
                                        <Text
                                            type="span"
                                            font="roboto-medium"
                                            color="white"
                                            fontSize="font-52"
                                            text={item?.header_text.title}
                                            maxLines="2"
                                        />
                                    }
                                     {item?.header_text && item?.header_text.description &&
                                        <Text
                                            type="span"
                                            font="roboto-light"
                                            color="white"
                                            fontSize="font-22"
                                            text={item?.header_text.description}
                                            maxLines="2"
                                        />
                                     }
                                </div>                                   
                            }

                                    </div>
                                </Container>
                            </SwiperSlide>
                        )
                    })}
                    {/* SLIDER BUTTONS */}
                    {slider.length >= 1 &&
                        <div className="swiper-buttons-container">
                            <div className="swiper-button-prev"> <ReactSVG src={"/images/buttonArrow.svg"} /></div>
                            <div className="swiper-button-next"> <ReactSVG src={"/images/buttonArrow.svg"} /></div>
                        </div>
                    }
                    <div className="mouse-icon-container">
                        <Image src="/images/seta_scrolldown.svg" alt="mouse" />
                    </div>
                </Swiper>
            }
        </section>
    );
}