import Text from "../../atoms/text";
import Image from "../../atoms/image";
import Button from "../../atoms/button";


export default function ProductInfo(props) {

    const {
        title,
        description,
        image,
        url
    } = props

    if (!props) {
        return <></>;
    }

    return (
        <div className="productInfo">
            <div>
            <div className="title-container">
                {/* PRODUCT NAME */}
                {title &&
                    <Text
                        type="h2"
                        font="poppins-semibold"
                        color="white"
                        fontSize="font-36"
                        text={title}
                        maxLines='3'
                    />
                }
                {/* PRODUCT LINK */}
                { url &&
                    <Button 
                        type="fullColored bold" 
                        bgcolor="bgDark" 
                        textcolor="textYellow" 
                        arrowRightYellow
                        page={url}
                    />
                }
            </div>
            {/* PRODUCT DESCRIPTION */}
            {description &&
                <Text
                    type="p"
                    font="roboto-light"
                    color="lightGrey"
                    fontSize="font-18"
                    text={description}
                />
            }
            </div>
            {/* PRODUCT ICON */}
            { image && image?.data && image?.data?.attributes &&
              image?.data?.attributes?.url &&
              <Image src={process.env.REACT_APP_API + image.data.attributes.url} alt={'product image'} />
            }
            {/* PRODUCT BUTTON LINK */}
            <div className="button-container">
                { url &&
                    <Button 
                        type="fullColored bold" 
                        bgcolor="bgDark" 
                        textcolor="textYellow" 
                        arrowRightYellow
                        page={url}
                    />
                }
            </div>
        </div>
    )
}
