import Text from "../../atoms/text";
import Container from "../../atoms/container";
import Image from "../../atoms/image";
import StatisticsBox from "../../molecules/statisticsBox";



export default function CaseStudyBody( props ){

    const {
        background_image,
        description,
        title,
        category,
        firstSection
    } = props;


    if (!props) {
        return <></>;
    }
     
    return (
        <section className='caseStudyBanner'>
            <div className="banner">
                { background_image && background_image?.data && background_image?.data?.attributes && background_image?.data?.attributes?.url &&
                    <Image src={process.env.REACT_APP_API + background_image.data.attributes.url} alt={'banner image'}/>
                }
                <div className="darkerBackground"></div>
                <Container size="md">
                    <div className={`text-container ${firstSection && 'margin-firstSectionTop'} margin-sectionBottom`}>
                        {title &&
                            <Text 
                                type="h1"
                                font="poppins-medium"
                                fontSize="font-52"
                                color="white"
                                text={title}
                                maxLines="2"
                            />
                        }
                        {description &&
                            <Text 
                                type="h2"
                                font="poppins-extralight"
                                fontSize="font-52"
                                color="white"
                                text={description}
                                maxLines="3"
                            />
                        }

                        {category &&
                            <Text 
                                className="category"
                                type="span"
                                font="poppins-regular"
                                fontSize="font-30"
                                color="white"
                                text={category}
                            />
                        }       
                    </div>
                </Container>
            </div> 
            {/*{props.info && props.info.length > 0 &&
                <div className="info-container">
                    <Container size="md">
                    { props.info.map ((item, index) => (
                        <StatisticsBox key={index} {...item}/>
                    ))}
                    </Container>
                </div>
            } */}  
        </section>

    )
}