import Text from "../../atoms/text";
import Button from "../../atoms/button";
import Container from "../../atoms/container";


export default function Section404 (props) {

    const {
        title,
        text,
        button
    } = props;

    if (!props) {
        return <></>
    }

    return (
        <section className="section404 margin-firstSectionTop margin-sectionBottom">
            <Container size="md">          
                {title &&
                <Text 
                    type="h1"
                    font="poppins-bold"
                    fontSize="font-200"
                    color="white"
                    text={title}
                />
                }
                {text &&
                <Text 
                    type="p"
                    font="poppins-regular"
                    fontSize="font-40"
                    color="grey"
                    text={text}
                />
                }
                {button && button.text &&
                    <Button type="lineColored" bgcolor="bgYellow" textcolor="textYellow" text={button.text} page="/"/>
                }
            </Container>
        </section>
    );
}