import { useEffect, useState } from "react";
import CaseStudyBanner from "../../components/sections/caseStudyBanner";
import CaseStudyBody from "../../components/sections/caseStudyBody";
import Statistics from "../../components/sections/statistics";


import { useParams, useNavigate } from "react-router-dom";


export default function CaseStudiesDetail({ currentLang, setHeaderDarkMode }) {

    /* HEADER DARKMODE */

    useEffect(() => {
        setHeaderDarkMode(false);
    }, []);

    /* **************** */


    let { slug } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [moreCaseStudies, setMoreCaseStudies] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!slug || !currentLang) return;
            let url = process.env.REACT_APP_API + `/api/casestudies?filters[slug][$eq]=${slug}&publicationState=${process.env.REACT_APP_PREVIEW}&[populate]=deep&locale=${currentLang}`
            try {
                const response = await fetch(url)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);


                const caseStudiesResponse = await fetch(
                    `${process.env.REACT_APP_API}/api/casestudies?filters[slug][$ne]=${slug}&locale=${currentLang}&pagination[limit]=3&sort=publishedAt:desc&fields[0]=slug&fields[1]=title&fields[2]=summary&populate[thumbnail_image][fields][0]=url`
                );
                if (!caseStudiesResponse.ok) {
                    throw new Error("Failed to fetch form data.");
                }
                const caseStudies = await caseStudiesResponse.json();
                setMoreCaseStudies(caseStudies.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    //Redirect in case wrong slug
    useEffect(() => {
        if (data && data.data.length === 0) {
            navigate("/resources/casestudies");
        }
    }, [data, navigate]);


    if (loading) return <div className="loadingPage"></div>;

    const banner_background_image = data?.data[0]?.attributes?.background_image;
    const banner_title = data?.data[0]?.attributes?.title;
    const banner_description = data?.data[0]?.attributes?.summary;
    const dataBody = data?.data[0]?.attributes?.textblock;
    const seeMoreSection = data?.data[0]?.attributes?.seeMoreSection;
    const statistics = data?.data[0]?.attributes?.statistics;


    return (
        <>
            {/* BANNER - CASE STUDY DETAIL */}
            {banner_background_image && banner_title && banner_description &&
                <CaseStudyBanner
                    background_image={banner_background_image}
                    description={banner_description}
                    title={banner_title}
                    firstSection
                />
            }
            {/* STATISTICS */}
            {statistics &&
                <Statistics {...statistics} />
            }
            {/* BODDY - CASE STUDY DETAIL */}
            {dataBody !== null && dataBody !== undefined &&
                <CaseStudyBody textBlock={dataBody} seeMoreSection={seeMoreSection} seeMoreContent={moreCaseStudies} />
            }

        </>
    )
}