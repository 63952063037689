import Image from "../../atoms/image";
import Text from "../../atoms/text";


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import Container from "../../atoms/container";


export default function Testimonials (props) {

    const {
        data
    } = props;

    
    if (!props) {
        return <></>
    }
    
    return (
        <section className="testimonials">          
        {data?.length > 0 &&
            <Swiper
                loop={true}
                autoplay={{
                    delay:8000,
                    stopOnLastSlide: false,
                }}
                speed={1000}
                className='swiper-transition' 
                direction='horizontal' 
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Autoplay]}
            >
                { data.map((item, index) => (
                    <SwiperSlide key={index}>
                        <Container size="lg">

                            {/* IMAGE | LOGO */}
                            <div>
                                {item && item?.attributes && item?.attributes?.logo && item?.attributes?.logo?.data && 
                                 item?.attributes?.logo?.data?.attributes && item?.attributes?.logo?.data?.attributes?.url &&
                                    <Image src={process.env.REACT_APP_API + item.attributes.logo.data.attributes.url} alt={'logo'}/>
                                }
                            </div>

                            <div>
                                {/* TEXT | QUOTE */}
                                {item?.attributes && item?.attributes?.quote &&
                                    <Text
                                        type="p"
                                        font="roboto-light" 
                                        color="darkGrey" 
                                        text={item.attributes.quote}
                                        fontSize="font-24"      
                                    />
                                }
                                {/* NAME */}
                                {item?.attributes && item?.attributes?.name &&
                                    <Text
                                        className="author"
                                        type="span"
                                        font="roboto-bold" 
                                        color="darkGrey" 
                                        text={item.attributes.name}
                                        fontSize="font-20"      
                                    />
                                }
                                {/* ROLE */}
                                {item?.attributes && item?.attributes?.role &&
                                    <Text
                                        className="position"
                                        type="span"
                                        font="roboto-light" 
                                        color="darkGrey" 
                                        text={item.attributes.role}
                                        fontSize="font-20"
                                    />
                                }
                            </div> 
                        </Container>                       
                    </SwiperSlide>
                ))}         
            </Swiper>
        }
        </section>
    );
}