import { useState } from "react";

import Text from "../../atoms/text";
import Container from "../../atoms/container";
import TextBox from "../../molecules/textBox";


export default function LegalDetail(props) {

    const {
        banner,
        legalSection,
        firstSection
    } = props;

    const [itemOpen, setItemOpen] = useState(-1);



    if (!props) {
        return <></>;
    }

    
    return (

        <section className={`legalDetail margin-sectionBottom ${firstSection && 'margin-firstSectionTop'}`}>
            <Container size="md">
                {banner && banner.pageTitle &&
                    <Text
                        type="h3"
                        font="poppins-medium"
                        fontSize="font-28"
                        color="yellow"
                        text={banner.pageTitle}
                        maxLines="2"
                    />
                }

                <div className="pages-container">
                    {/*  TABS LEGAL  */}
                    <div className={`frontPage ${itemOpen !== -1 ? 'tabs' : ''}`}>
                        {/*  TAB NAME  */}
                        {legalSection && legalSection.map((item, index) => (
                            <button key={index} onClick={() => setItemOpen(index)} className={`${itemOpen === index ? 'open' :'' }`}>
                                <Text
                                    type="h2"
                                    font="poppins-medium"
                                    fontSize="font-36"
                                    color="black"
                                    text={item?.title}
                                />
                            </button>
                        ))}
                    </div>

                    {/*  TAB CONTENT  */}
                    {legalSection && legalSection.map((item, index) => (
                        <div className={`infoPage page-${index} ${itemOpen === index ? 'open' : ''}`} key={index}>
                            {/*  TITLE  */}
                            <Text
                                type="h1"
                                font="poppins-medium"
                                fontSize="font-66"
                                color="black"
                                text={item?.title}
                                maxLines=""
                            />
                            {/*  TEXT BLOCK  */}
                            <div className="description">
                                {item.description &&
                                    <TextBox text={item.description} />
                                }
                                {item.description && item.description.map((text, tIndex) => (
                                    <div className="paragraph" key={tIndex}>
                                        {text.bullets && text.bullets.length > 0 &&
                                            <ul>
                                                {text.bullets.map((bullet, bIndex) => (
                                                    <li key={bIndex}>
                                                        <Text
                                                            type="span"
                                                            font="roboto-light"
                                                            fontSize="font-24"
                                                            color="black"
                                                            text={bullet?.text}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </section>
    )
}