import { useState, useEffect } from "react";

import Button from "../../components/atoms/button";

import BlocksLinkList from "../../components/sections/blocksLinkList";
import IntroSection from "../../components/sections/introSection";
import Container from "../../components/atoms/container";
import CategoriesList from "../../components/sections/categoriesList";


export default function CaseStudies({ currentLang, setHeaderDarkMode }) {

  /* HEADER DARKMODE */
  useEffect(() => {
    setHeaderDarkMode(false);
  }, []);

  /* **************** */

  const [data, setData] = useState(null);
  const [page, setPage] = useState(null);
  const [categories, setCategories] = useState(null);
  const [pagination, setPagination] = useState(1)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response1, response2, response3] = await Promise.all([
          fetch(`${process.env.REACT_APP_API}/api/casestudies?populate=thumbnail_image,casestudycategory&locale=${currentLang}&publicationState=${process.env.REACT_APP_PREVIEW}&pagination[page]=${pagination}&sort[0]=pin:desc&sort[1]=updatedAt:desc`),
          fetch(`${process.env.REACT_APP_API}/api/casestudiespage?populate=banner.background_image&locale=${currentLang}&populate=loadMore`),
          fetch(`${process.env.REACT_APP_API}/api/casestudycategories?populate=deep&locale=${currentLang}`),
        ])
        if (!response1.ok || !response2.ok || !response3.ok) {
          throw new Error('Network error');
        }

        const [result1, result2, result3] = await Promise.all([
          response1.json(),
          response2.json(),
          response3.json()
        ])


        setData((prevData) => (pagination === 1 ? result1.data : [...prevData, ...result1.data]));
        setCategories(result3)
        setPage(result2);
        setHasMore(result1.meta.pagination.page < result1.meta.pagination.pageCount);

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pagination]);

  const loadMore = () => {
    if (hasMore) {
      setPagination((prev) => prev + 1);
    }
  };


  // CASE STUDIES SLIDER ACTIVE ITEM
  const [caseStudyItemActive, setCaseStudyItemActive] = useState(0);


  if (loading) return <div className="loadingPage"></div>;

  function filterByCategory(list) {
    return list.filter(item =>
      item?.attributes?.casestudycategory?.data?.attributes?.category === categories.data[caseStudyItemActive].attributes.category
    );
  }


  // SET INFO TO SHOW CASE STUDIES
  const list = data;
  const banner = page?.data?.attributes?.banner;

  return (
    <>

      {/* BANNER */}
      {banner !== null && banner !== undefined &&
        <IntroSection {...banner} firstSection />
      }
      {/* CATEGORIES LIST */}
      {categories &&
        <CategoriesList
          {...categories}
          isCaseStudies
          caseStudyItemActive={caseStudyItemActive}
          setCaseStudyItemActive={setCaseStudyItemActive}
        />
      }
      {
        caseStudyItemActive == 0 ?
          <BlocksLinkList list={list} noMarginBottom />
          :
          <BlocksLinkList list={filterByCategory(list)} noMarginBottom />
      }
      {
        hasMore &&
        <div className="showMore-button-container margin-sectionBottom">
          <Container>
            <Button
              onclick={loadMore}
              type="fullColored bold"
              bgcolor="bgYellow"
              textcolor="textBlack"
              text={page?.data?.attributes?.loadMore?.text}
            />
          </Container>
        </div>
      }
    </>
  )
}